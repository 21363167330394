import React, { useState, useEffect } from 'react';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import moment from 'moment';
import LoadingComponent from '../../Components/Loader';
import ApiGatewayFunction from '../../UserConfigs/ApiGateWayFunction';
import IntegrationsDate from '../IntegrationsDate';
import FiltersGA4 from './FiltersGA4';
import ParseGA4 from './ParseGA4';
import TableGSC from '../IntegrationsGSC/TableGSC';
import BarChart from '../../ChartsDesktop/BarChart';
import IntegrationsNoData from '../IntegrationsNoData';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DevicesIcon from '@mui/icons-material/Devices';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import AdsClickTwoToneIcon from '@mui/icons-material/AdsClickTwoTone';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const PreviewGa4 = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const support_key = configArray.support_key
    const support_id = configArray.support_id
    const clientMapId = configArray.clientMapId
    const patternAPI = configArray.patternAPI
    const endpointStage = configArray.testingState
    const auth0_user_id = configArray.auth0_user_id
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const currentMetrics = configArray.currentMetrics
    const integrations = props.integrations
    const sitesList = integrations.ga4.sites
    const titleProvider = props.titleProvider
    const currentInt = props.currentInt
    const previewTitle = titleProvider(currentInt)

    const [currentSite, setCurrentSite] = useState(sitesList[0])
    const [currentContent, setCurrentContent] = useState('date')
    const [metric, setMetric] = useState('totalUsers')
    const [dimension, setDimension] = useState('date')
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(null)
    const [filtersOpen, setFiltersOpen] = useState(false)
    const filterBase = {
        "filter": {
            "fieldName": "date",
            "stringFilter": {
                "value": "20",
                "matchType" : "contains"
            }
         }
    }
    const [filters, setFilters] = useState(filterBase)
    const metricList = ['totalUsers', 'newUsers', 'activeUsers', 'sessions', 'bounceRate', 'averageSessionDuration', 'eventCount', 'keyEvents']

    const currentDate = moment.tz('Europe/Malta');
    const end = currentDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
    const start = currentDate.clone().subtract(31, 'day').format('YYYY-MM-DD');
    const [dateRange, setDateRange] = useState({
        start: start,
        end: end
    })
    const isDate = currentContent !== 'date'

    const filterhandler = (filters) => {
        setFilters(filters)
    }

    const contentHandler = (content) => {
        setCurrentContent(content)
        setDimension(content)
    }

    const resetHandler = () => {
        setFilters(filterBase)
    }

    const ga4Query = {
        "request_type": "ga4",
        "request_subType": "query",
        "site": sitesList,
        "client_id": clientMapId,
        "query_site": currentSite,
        "auth0_user_id": auth0_user_id,
        "query": {
            "dimensions": [
                {
                    "name": dimension
                }
            ],
            "metrics": [
                { "name": "totalUsers" },
                { "name": "newUsers" },
                { "name": "activeUsers" },
                { "name": "sessions" },
                { "name": "bounceRate" },
                { "name": "averageSessionDuration" },
                { "name": "eventCount" },
                { "name": "keyEvents" }
            ],
            "dateRanges": [
                {
                    "startDate": dateRange.start,
                    "endDate": dateRange.end,
                    "name": "userDateRange"
                }
            ],
            "dimensionFilter": filters,
            "limit": "100",
            "keepEmptyRows": false
        }
    }

    useEffect(() => {
        const endpointStage = configArray.testingState;

        ApiGatewayFunction(ga4Query, patternAPI, support_key, support_id, setIsLoading, setResponse, endpointStage);

    }, [configArray, currentContent, currentSite, dateRange, filters]);


    if (isLoading) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (!response) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const ga4DataResponse = response.data.body.query_data
    const ga4Data = ParseGA4(ga4DataResponse, dimension)
    
    const isEmpty = ga4Data?.labels?.[0] === "NO_DATA";

    const PanelComponent = ({ text, icon, content }) => {

        const IconProp = icon

        return <ListItem sx={{ alignItems: 'center', height: windowHeight * 0.075 }} >
            <ListItemButton onClick={() => contentHandler(content)}>
                <ListItemIcon>
                    <IconProp sx={{ color: theme.backgroundColor2 }} />
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={<Typography style={{ fontSize: windowHeight * 0.0175, color: theme.color, fontFamily: 'Electrolize' }}>{text}</Typography>}
                />
            </ListItemButton>
        </ListItem>


    }


    const SelectComponent = ({ value, setValue, map, title, disabled }) => {
        return (<View style={{ height: '7%', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 0 }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize' }}>
                <FormHelperText>{title}</FormHelperText>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    label="Type"
                    disabled={disabled}
                    value={value}
                    sx={{
                        fontFamily: 'Electrolize',
                        width: '100%',
                        backgroundColor: theme.backgroundColor,
                        color: theme.color,
                        flexDirection: 'row',
                        height: 30

                    }}
                    onChange={(event) => setValue(event.target.value)}
                    input={<OutlinedInput />}
                >
                    {map}
                </Select>

            </FormControl>
        </View>)

    }

    const metricsMap = metricList.map((item, index) => (
        <MenuItem
            key={`dimension-${index}`} value={item}
            sx={{ fontFamily: 'Electrolize' }}
        >
            {item}
        </MenuItem>
    ));


    const sitesMap = sitesList.map((item, index) => (
        <MenuItem
            key={`dimension-${index}`} value={item}
            sx={{ fontFamily: 'Electrolize' }}
        >

            {item}
        </MenuItem>
    ));

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.backgroundColor2,
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        width: '50%',
        height: 30,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
            color: 'white'
        },
    }));

    const ButtonComponent = ({ theme }) => {
        return (<View style={{ height: '10%', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 0, padding: '1%' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize' }}>
                <FormHelperText>Filters</FormHelperText>
                <View style={{ width: '100%', alignItems: 'end', flexDirection: 'row', gap: 2 }}>
                    <ColorButton variant="outlined"
                        theme={theme}
                        onClick={() => resetHandler()}>
                        Reset
                    </ColorButton>
                    <ColorButton variant="outlined"
                        theme={theme}
                        onClick={() => setFiltersOpen(true)}>
                        Open
                    </ColorButton>
                </View>


            </FormControl>
        </View>)
    }

    const DateComponent = ({value, setValue, map, title, disabled, theme}) => {
        return (<View style={{ height: '10%', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 0 }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize' }}>
                <FormHelperText>{title}</FormHelperText>
                    <IntegrationsDate 
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        theme={theme}/>
            </FormControl>
        </View>)
    }

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    const chartComponent = () => {
        if (isEmpty) {
            return (
                <IntegrationsNoData theme={theme} />
            )
        } else if (currentContent === 'date') {
            return (
                <View style={lineChart}>
                    <BarChart
                        dataProp={ga4Data.agg_result}
                        labels={ga4Data.labels}
                        theme={theme}
                        metric={metric}
                        timeFrame={'daily'}
                        client={client_lower}
                        currentMetrics={currentMetrics}
                    />
                </View>
            )
        } else if (currentContent !== 'daily') {
                    return (
                        <View style={lineChart}>
                            <TableGSC
                                dataProp={ga4Data.agg_result}
                                currentDim={dimension}
                                theme={theme}
                            />
                        </View>
                    )
                }
    }

    return (
        <View style={{ width: '100%', height: windowHeight * 0.93, borderColor: 'grey', borderWidth: 1, flexDirection: 'column' }}>

            <View style={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                <View style={{ height: '100%', width: '20%', borderColor: 'grey', borderWidth: 1, flexDirection: 'column' }}>
                    <SelectComponent
                        value={currentSite}
                        setValue={setCurrentSite}
                        map={sitesMap}
                        title={'Select Site'}
                        disabled={false}
                    />
                    <DateComponent
                        value={metric}
                        setValue={setMetric}
                        map={metricsMap}
                        title={'Select Date Range'}
                        disabled={false}
                        theme={theme}
                    />
                    <SelectComponent
                        value={metric}
                        setValue={setMetric}
                        map={metricsMap}
                        title={'Select Metric'}
                        disabled={isDate}
                    />
                    
                    <ButtonComponent theme={theme} />
                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: theme.backgroundColor, alignItems: 'center', justifyContent: 'center' }}>
                        <nav aria-label="main mailbox folders">
                            <List sx={{ alignItems: 'center' }} disablePadding >
                                <Divider />
                                <PanelComponent text={'Daily'} icon={ShowChartIcon} content={'date'} />
                                <PanelComponent text={'Session Channel Group'} icon={AccountTreeTwoToneIcon} content={'sessionDefaultChannelGroup'} />
                                <PanelComponent text={'Session Campaign'} icon={CampaignTwoToneIcon} content={'sessionCampaignName'} />
                                <PanelComponent text={'Session Source/Medium'} icon={CampaignTwoToneIcon} content={'sessionSourceMedium'} />
                                <PanelComponent text={'Device'} icon={DevicesIcon} content={'deviceCategory'} />
                                <PanelComponent text={'Events'} icon={AdsClickTwoToneIcon} content={'eventName'} />
                                <PanelComponent text={'Region'} icon={FmdGoodTwoToneIcon} content={'region'} />
                                <PanelComponent text={'Landing Page'} icon={LinkTwoToneIcon} content={'landingPage'} />

                            </List>
                        </nav>
                    </Box>



                </View>
                <View style={{ height: '100%', width: '80%', borderColor: 'grey', borderWidth: 1, flexDirection: 'column', padding: '1%' }}>
                    <View style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>

                        {chartComponent()}

                    </View>



                </View>

            </View>
            <FiltersGA4 
                theme={theme}
                open={filtersOpen}
                setOpen={setFiltersOpen}
                filterhandler={filterhandler}
            />

        </View>
    )


}

export default PreviewGa4