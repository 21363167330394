import React, { useEffect, useState } from 'react';
import { Modal, View, Pressable, Dimensions, Text } from 'react-native';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';

import StudioCreateDataGrid from './StudioCreateDataGrid';

const windowWidth = Dimensions.get('window').width;

const StudioCreateStep1 = (props) => {

    const theme = props.theme
    const setVisible = props.setVisible
    const setCurrentStep = props.setCurrentStep
    const setCurrentStepBase = props.setCurrentStepBase
    const schemaData = props.schemaData
    const currentMetrics = props.currentMetrics
    const tables = Object.keys(schemaData)
    const currentTable = props.currentTable
    const setCurrentTable = props.setCurrentTable
    const currentSchema = schemaData[currentTable] || []
    LicenseInfo.setLicenseKey('3dd7f0e5176fd2661730ecde2785f53fTz0xMDU4OTgsRT0xNzY4NDM1MTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');
    

    const clickTableHandler = (input) => {
        setCurrentTable(input)
    }

    const chipSelectedColour = (item) => {

        if (!currentTable) {
            return theme.backgroundColor2
        } if (item === currentTable) {
            return theme.backgroundColor2
        } else {
            return 'grey'
        }

    }

    const ColorChip = styled(Chip)(({ theme, label }) => ({
        color: theme.backgroundColor,
        //fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: chipSelectedColour(label),
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        }
    }));

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.backgroundColor,
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        width: '75%',
        backgroundColor: theme.backgroundColor2,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        },
    }));


    const tableItem = (item, index) => {

        return <View style={{ padding: '0.5%', width: '75%' }} key={`${item}_${index}`}>
            <ColorChip
                label={item}
                onClick={() => clickTableHandler(item)}
                theme={theme}
            />
        </View>

    }

    const clickHandler = (type) => {

        if (type === 'close') {
            setCurrentStepBase(0)
        } else if (type === 'save') {
            if (!currentTable) {
                return 
            } else {
                setCurrentStep(2)
            }
            
        }

    }

    const schemaViewer = () => {

        if (currentSchema.length === 0) {
            return <View style={{ width: '75%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems:'center', justifyContent: 'center' }}>
            <Text style={{fontSize: windowWidth * 0.025, fontFamily: 'Electrolize', color: 'grey', fontWeight: 600}}>
                Select Dataset
            </Text>
            <Text style={{fontSize: windowWidth * 0.015, fontFamily: 'Electrolize', color: 'grey', fontWeight: 600}}>
                Then Hit Next
            </Text>

        </View>
        } else {
            return <View style={{ width: '75%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems:'center', justifyContent: 'center' }}>
            <StudioCreateDataGrid
                theme={theme}
                dataProp={currentSchema}
                currentMetrics={currentMetrics}
            />

        </View>
        }
            
        }


    return (
        <View style={{ width: '100%', height: '100%', flexDirection: 'column' }}>
            <View style={{ width: '100%', height: '90%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {schemaViewer()}
                <View style={{ width: '25%', height: '100%', padding: '1%', overflow: 'auto', borderColor: 'grey', borderWidth: 1, alignItems: 'center' }}>
                    {tables.map((item, index) => (
                        tableItem(item, index)
                    ))}

                </View>



            </View>
            <View style={{ width: '100%', height: '10%', flexDirection: 'row', alignItems: 'end', borderColor: 'grey', borderWidth: 1, justifyContent: 'center' }}>
                <View style={{ width: '75%', justifyContent: 'center', borderColor: 'grey', borderWidth: 1, height: '100%', padding: '1%'}}>

                    <Text 
                        style={{fontSize: windowWidth * 0.01, fontFamily: 'Electrolize', color: theme.color, fontWeight: 600}}
                    >
                        Current Table: {currentTable}
                    </Text>

                </View>
                <View style={{width: '12.5%', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                    <ColorButton
                        onClick={() => clickHandler('close')}
                        theme={theme}>
                    Close

                    </ColorButton>
                </View>
                <View style={{width: '12.5%', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                    <ColorButton

                        onClick={() => clickHandler('save')}
                        theme={theme}>
                    Next

                    </ColorButton>
                </View>

            </View>

        </View>
    )
}

export default StudioCreateStep1