import React, {useEffect, useState} from 'react';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { LicenseInfo } from '@mui/x-license-pro';
import { Dimensions } from 'react-native'
import { View } from 'react-native'
import moment from 'moment'
import WebFont from 'webfontloader';
import { Divider, Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function IntegrationsDate(props) {

  LicenseInfo.setLicenseKey('3dd7f0e5176fd2661730ecde2785f53fTz0xMDU4OTgsRT0xNzY4NDM1MTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');  
  const theme = props.theme
  const dateRange = props.dateRange
  const setDateRange = props.setDateRange
  const large = true
  const startDateProp = dateRange.start
  const endDateProp = dateRange.end
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 30);
  previousDate.setDate(1)
  const startDateString = previousDate.toISOString().slice(0, 10);
  const startDateBase = moment(startDateProp, "YYYY-MM-DD");

    // Calculate maxDate (+60 days from StartDateProp)
    const maxDate = startDateBase.clone().add(60, "days");
  

    useEffect(() => {
      WebFont.load({
        google: {
          families: ['Droid Sans', 'Electrolize']
        }
      });
     }, []);

     const themeCustom = createTheme({
      palette: {
        primary: {
          main: theme.backgroundColor2,
        },
        secondary: purple,
        main: purple,
      },
    });

    


    const handleTest = (value) => {
      let defaultStart;
      let defaultEnd;

      if (!value[0] && !value[0]) {
        defaultStart = startDateString
        defaultEnd = yesterdayProp

      } else {
        const startDate = value[0];  // Assume this is already a Moment object
        const endDate = value[1];    // Assume this is already a Moment object
        
        // Format start date and end date as YYYY-MM-DD
        defaultStart = startDate.format('YYYY-MM-DD');
        defaultEnd = endDate.format('YYYY-MM-DD');
      }
   
      const prop = {
        start: defaultStart,
        end: defaultEnd,
      };
        setDateRange(prop)
    }


      let responseText;

      if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
      } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.01
      } else {responseText = windowHeight * 0.017}

      const shortcutsItems = [
        {
          label: 'Yesterday',
          getValue: () => {
            const yesterdayStart = moment().subtract(1, 'day').startOf('day');
            const yesterdayEnd = moment().subtract(1, 'day').endOf('day');
            return [yesterdayStart, yesterdayEnd];
          },
        },
        {
          label: 'This Week',
          getValue: () => {
            const startOfWeek = moment().startOf('week');
            const endOfYesterday = moment().subtract(1, 'day').endOf('day');
            return [startOfWeek, endOfYesterday];
          },
        },
        {
          label: 'Last Week',
          getValue: () => {
            const startOfLastWeek = moment().subtract(1, 'week').startOf('week');
            const endOfLastWeek = moment().subtract(1, 'week').endOf('week');
            return [startOfLastWeek, endOfLastWeek];
          },
        },
        {
          label: 'Last 7 Days',
          getValue: () => {
            const sevenDaysAgo = moment().subtract(7, 'days').startOf('day');
            const endOfYesterday = moment().subtract(1, 'day').endOf('day');
            return [sevenDaysAgo, endOfYesterday];
          },
        },
        {
          label: 'Current Month',
          getValue: () => {
            const startOfMonth = moment().startOf('month');
            const endOfMonth = moment().endOf('month');      
            return [startOfMonth, endOfMonth];
          },
        },
        {
          label: 'Last Month',
          getValue: () => {
            const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
            const endOfLastMonth = moment().subtract(1, 'month').endOf('month');
            return [startOfLastMonth, endOfLastMonth];
          },
        }
      ];
    
      function CustomRangeShortcuts(props) {
        const { items, onChange, isValid } = props;
      
        if (items == null || items.length === 0) {
          return null;
        }
      
        const resolvedItems = items.map((item) => {
          const newValue = item.getValue({ isValid });
      
          return {
            label: item.label,
            onClick: () => {
              onChange(newValue);
            },
            disabled: !isValid(newValue),
          };
        });
      
        return (
          <Box
            sx={{
              gridRow: 1,
              gridColumn: 2,
            }}
          >
            <List
              dense
              sx={(theme) => ({
                display: 'flex',
                px: theme.spacing(4),
                '& .MuiListItem-root': {
                  pt: 0,
                  pl: 0,
                  pr: theme.spacing(1),
                },
              })}
            >
              {resolvedItems.map((item) => {
                return (
                  <ListItem key={item.label}>
                    <Chip {...item} sx={{backgroundColor: theme.backgroundColor2, color: 'white' }} />
                  </ListItem>
                );
              })}
            </List>
            <Divider />
          </Box>
        );
      }

  return (
    <View style={{alignItems: 'center', width: '100%'}}>
    <View style={{
        borderColor : 'yellow', 
        borderWidth : 0 , 
        fontFamily : 'Electrolize',
        width: '100%',
     

        }}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={themeCustom} components={['SingleInputDateRangeField']}>
          <DesktopDateRangePicker
            onAccept={handleTest}
            slots={{ field: SingleInputDateRangeField, shortcuts: CustomRangeShortcuts, }}
            defaultValue = {[moment(startDateProp), moment(endDateProp)]}
            format="YYYY-MM-DD"
            style={{fontFamily : 'Electrolize' , backgroundColor : theme.backgroundColor2, height: 30}}
            slotProps={{
              shortcuts: {
                    items: shortcutsItems,
                },
                toolbar: {
                    hidden: true,
                },
                actionBar: {
                  actions: ["accept"]
                },
              textField: () => ({
                color: 'main',
                inputProps: {
                  
                  style: {
                    color: theme.color, // Replace with your custom text color
                    //border: `1px solid ${theme.backgroundColor2}`,
                    borderRadius: 5,
                    fontFamily : 'Electrolize',
                    fontSize: responseText,
                    "&:hover > fieldset": { borderColor: "#C7C8CD" },
                    height: 3
                  },
                },
                focused: false,
              })
            }}

          />
          </ThemeProvider>
    </LocalizationProvider>
    </View>
</View>
  );
}