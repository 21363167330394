import React, { useState, useEffect } from 'react';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import moment from 'moment';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import SearchIcon from '@mui/icons-material/Search';
import DevicesIcon from '@mui/icons-material/Devices';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import ApiGatewayFunction from '../../UserConfigs/ApiGateWayFunction';
import LoadingComponent from '../../Components/Loader';
import ParseGSC from './ParseGSC';
import TableGSC from './TableGSC';
import FiltersGSC from './FiltersGSC';
import IntegrationsDate from '../IntegrationsDate';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import BarChart from '../../ChartsDesktop/BarChart';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const PreviewGsc = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const support_key = configArray.support_key
    const support_id = configArray.support_id
    const clientMapId = configArray.clientMapId
    const patternAPI = configArray.patternAPI
    const endpointStage = configArray.testingState
    const auth0_user_id = configArray.auth0_user_id
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const currentMetrics = configArray.currentMetrics
    const integrations = props.integrations
    const sitesList = integrations.gsc.sites
    const currentInt = props.currentInt
    const titleProvider = props.titleProvider
    const previewTitle = titleProvider(currentInt)
    const urlEncode = (value) => {
        return value.replaceAll(':', '%3A').replaceAll('/', '%2F');
    }
    const [currentSite, setCurrentSite] = useState(urlEncode(sitesList[0].site))
    const [currentContent, setCurrentContent] = useState('date')
    const [metric, setMetric] = useState('clicks')
    const [dimension, setDimension] = useState('date')
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(null)
    const [filtersOpen, setFiltersOpen] = useState(false)
    const [filters, setFilters] = useState([])
    const metricList = ['impressions', 'clicks', 'ctr', 'position']
    const dimensionlist = ['date', 'country']

    const currentDate = moment.tz('Europe/Malta');
    const end = currentDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
    const start = currentDate.clone().subtract(31, 'day').format('YYYY-MM-DD');
    const [dateRange, setDateRange] = useState({
            start: start,
            end: end
        })
    
    const isDate = currentContent !== 'date'


    const urlCleaner = (value) => {
            return value.replaceAll('%3A', ':').replaceAll('%2F', '/');
    };

    const filterhandler = (filters) => {
        setFilters(filters)
    }

    const encodeSiteUrls = (data) => {
        return data.map(({ site, isDomainProperty }) => {
            if (isDomainProperty === "yes") {
                return `sc-domain:${site}`; // Do not encode if it's a domain property
            } else {
                return site.replace(/:/g, "%3A").replace(/\//g, "%2F"); // Encode `:` and `/`
            }
        });
    };


    const sitesArray = encodeSiteUrls(sitesList)
    
    const formatQuerySite = (url) => {
        if (url.includes('sc-domain')) {
            return url
        } else {
            return url.replace(/:/g, "%3A").replace(/\//g, "%2F")
        }

    }


    const gcsQuery = {
        "request_type": "gcs",
        "request_subType": "query",
        "site": sitesArray,
        "client_id": clientMapId,
        "auth0_user_id": auth0_user_id,
        "query": {
            "query_site": formatQuerySite(currentSite),
            "startDate": dateRange.start,
            "endDate": dateRange.end,
            "rowLimit": 100,
            "dimensions": [
                dimension
            ],
            "dimensionFilterGroups": filters
        }
    }

    useEffect(() => {
        const endpointStage = configArray.testingState;
        ApiGatewayFunction(gcsQuery, patternAPI, support_key, support_id, setIsLoading, setResponse, endpointStage);

    }, [configArray, currentContent, currentSite, filters, dateRange]);

    if (isLoading) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (!response) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const gscDataResponse = response.data.body.query_data
    const gscData = ParseGSC(gscDataResponse, dimension)

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    const contentHandler = (content) => {

        setCurrentContent(content)
        setDimension(content)

    }

    const resetHandler = () => {
        setFilters([])
    }

    const chartComponent = () => {
        if (currentContent === 'date') {
            return (
                <View style={lineChart}>
                    <BarChart
                        dataProp={gscData.agg_result}
                        labels={gscData.labels}
                        theme={theme}
                        metric={metric}
                        timeFrame={'daily'}
                        client={client_lower}
                        currentMetrics={currentMetrics}
                    />
                </View>
            )
        } else if (currentContent !== 'daily') {
            return (
                <View style={lineChart}>
                    <TableGSC
                        dataProp={gscData.agg_result}
                        currentDim={dimension}
                        theme={theme}
                    />
                </View>
            )
        }
    }


    const PanelComponent = ({ text, icon, content }) => {

        const IconProp = icon

        return <ListItem  sx={{alignItems: 'center', height: windowHeight * 0.075}} >
                    <ListItemButton onClick={() => contentHandler(content)}>
                        <ListItemIcon>
                            <IconProp sx={{color: theme.backgroundColor2}} />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography style={{ fontSize: windowHeight * 0.0175, color: theme.color, fontFamily : 'Electrolize' }}>{text}</Typography>}
                        />
                    </ListItemButton>
                </ListItem>
        

    }

    const SelectComponent = ({ value, setValue, map, title, disabled }) => {
        return (<View style={{ height: '7%', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 0 }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize' }}>
                <FormHelperText>{title}</FormHelperText>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    label="Type"
                    disabled={disabled}
                    value={value}
                    sx={{
                        fontFamily: 'Electrolize',
                        width: '100%',
                        backgroundColor: theme.backgroundColor,
                        color: theme.color,
                        flexDirection: 'row',
                        height: 30

                    }}
                    onChange={(event) => setValue(event.target.value)}
                    input={<OutlinedInput />}
                >
                    {map}
                </Select>

            </FormControl>
        </View>)

    }

    const metricsMap = metricList.map((item, index) => (
        <MenuItem
            key={`dimension-${index}`} value={item}
            sx={{ fontFamily: 'Electrolize' }}
        >
            {item}
        </MenuItem>
    ));


    const sitesMap = sitesArray.map((item, index) => (
        <MenuItem
            key={`dimension-${index}`} value={item}
            sx={{ fontFamily: 'Electrolize' }}
        >

            {urlCleaner(item)}
        </MenuItem>
    ));

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.backgroundColor2,
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        width: '50%',
        height: 30,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
            color: 'white'
        },
    }));

    const ButtonComponent = ({theme}) => {
        return (<View style={{ height: '10%', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 0, padding: '1%' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize' }}>
                <FormHelperText>Filters</FormHelperText>
                <View style={{width: '100%', alignItems: 'end', flexDirection: 'row', gap: 2}}>
                    <ColorButton variant="outlined"
                        theme={theme}
                        onClick={() => resetHandler()}>
                            Reset
                    </ColorButton>
                    <ColorButton variant="outlined"
                        theme={theme}
                        onClick={() => setFiltersOpen(true)}>
                            Open
                    </ColorButton>
                </View>
                

            </FormControl>
        </View>)
    }

    const DateComponent = ({value, setValue, map, title, disabled, theme}) => {
        return (<View style={{ height: '10%', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 0 }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize' }}>
                <FormHelperText>{title}</FormHelperText>
                    <IntegrationsDate 
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        theme={theme}/>
            </FormControl>
        </View>)
    }

    return (
        <View style={{ width: '100%', height: windowHeight * 0.93, borderColor: 'grey', borderWidth: 1, flexDirection: 'column' }}>
            <View style={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                
                <View style={{ height: '100%', width: '20%', borderColor: 'grey', borderWidth: 1, flexDirection: 'column' }}>
                    <SelectComponent
                        value={currentSite}
                        setValue={setCurrentSite}
                        map={sitesMap}
                        title={'Select Site'}
                        disabled={false}
                    />
                    <DateComponent
                        value={metric}
                        setValue={setMetric}
                        map={metricsMap}
                        title={'Select Date Range'}
                        disabled={false}
                        theme={theme}
                    />
                    <SelectComponent
                        value={metric}
                        setValue={setMetric}
                        map={metricsMap}
                        title={'Select Metric'}
                        disabled={isDate}
                    />
                    <ButtonComponent theme={theme}/>
                    
                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: theme.backgroundColor, alignItems: 'center', justifyContent: 'center'}}>
                        <nav aria-label="main mailbox folders">
                        <List sx={{alignItems: 'center'}} >
                            <Divider />
                            <PanelComponent text={'Daily'} icon={ShowChartIcon} content={'date'} />
                            <PanelComponent text={'Country'} icon={LanguageIcon} content={'country'} />
                            <PanelComponent text={'Query'} icon={SearchIcon} content={'query'} />
                            <PanelComponent text={'Device'} icon={DevicesIcon} content={'device'} />
                            <PanelComponent text={'Page'} icon={LinkIcon} content={'page'} />
                            <Divider />
                        </List>
                        </nav>
                    </Box>

                    

                </View>
                <View style={{ height: '100%', width: '80%', borderColor: 'grey', borderWidth: 1, flexDirection: 'column' }}>

                    <View style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        {chartComponent()}    
                    </View>

                    

                </View>
            </View>
            <FiltersGSC 
                theme={theme}
                open={filtersOpen}
                setOpen={setFiltersOpen}
                filterhandler={filterhandler}
            />

        </View>
    )

}

export default PreviewGsc