import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import MACDChart from './MACDChart';
import FiltersEnhancedSingle from '../../ComponentsEnhanced/FiltersEnhancedSingle';
import WebFont from 'webfontloader';
import StatFunctionMACD from '../MovingAverage/StatisticsFunctions/StatFunctionMACD';
import moment from 'moment';
import Switch from '@mui/material/Switch';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const MACDPanels = (props) => {

    const client = props.client
    const dataProp = props.dataProp
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const currentMetrics = props.currentMetrics
    const filterParams = props.filterParams
    const currentData = props.agg_daily
    const timeFrame = props.timeFrame
    const statModel = props.statModel
    const reportList = props.reportList
    const [selectedComponent, setSelectedComponent] = useState("Row1_Line1");
    const [modalOpen, setModalOpen] = useState(false)
    const [signalLines, setSignalLines] = useState(false)
    const [checked, setChecked] = useState(false)
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const barBrands = props.barBrands
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    
    const handleChange = (event) => {
        setChecked(event.target.checked);
        setSignalLines(event.target.checked)
        
      };

    const calcMetrics = (data) => {
    
            let output = data.map(item => {
    
                return {
                    date: item.date,
                    impressions: item.impressions,
                    clicks: item.clicks,
                    spend: item.spend,
                    regs: item.regs,
                    ftds: item.ftds,
                    ngr: item.ngr || 0,
                    cpc: item.clicks > 0 ? item.spend / item.clicks : 0,
                    cpc: item.impressions > 0 ? item.clicks / item.impressions : 0,
                    cpa: item.ftds > 0 ? item.spend / item.ftds : 0,
                    cpr: item.regs > 0 ? item.spend / item.regs : 0,
                    roas: item.spend > 0 ? item.ngr / item.spend : 0,
                }
            })
    
            output.sort((a, b) => {
                const dateA = moment.tz(a.date, 'Europe/Paris');
                const dateB = moment.tz(b.date, 'Europe/Paris');
                return dateA - dateB;
            });
    
            return output
        }

    const aggResult = calcMetrics(currentData)

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    }

    const dateLabels = getUniqueValues(aggResult, 'date')

    const macdArray = StatFunctionMACD(aggResult);

    const macdCPA = macdArray.macdCPA;
    const macdFTD = macdArray.macdFTD;
    const macdCPR = macdArray.macdCPR;
    const macdSpend = macdArray.macdSpend;
    const macdRegs = macdArray.macdRegs;
    const macdROAS = macdArray.macdROAS;

    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const renderComponentContent = () => {
        if (selectedComponent === 'Row1_Line1') {
            return <Row1Line1 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line2') {
            return <Row1Line2 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line3') {
            return <Row1Line3 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Bar1') {
            return <Row2Bar1 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Mixed1') {
            return <Row2Mixed1 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Bar2') {
            return <Row2Bar2 style={panelMain} />;
        } else {
            return null;
        }
    };

    const panelMain = {

        width: '100%',
        height: windowHeight * 0.65,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',       
    }

    const panelChild = {
        flex: 1,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    let topValue, rightValue;

    if (windowWidth > 1800) {
        topValue = "10%"
        rightValue = "1%"
    } else {
        topValue = "11.5%"
        rightValue = "1%"
    }

    const headerToggleContainer = {
        position: 'absolute',
        right: rightValue,
        top: topValue,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
        flexDirection: 'row',
        fontFamily: 'Electrolize',
    }

    const cumuText = {
        fontFamily: 'Electrolize',
        color: theme.color,
        fontWeight: 600
    }


    const Row1Line1 = () => {

        return (
            <View style={lineChart}>
                <MACDChart
                        dataProp={macdCPA}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'cpa'}
                        signalLines={signalLines}
                    />
            </View>)
    }

    const Row1Line2 = () => {

        return (
            <View style={lineChart}>
                <MACDChart
                        dataProp={macdFTD}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'ftds'}
                        signalLines={signalLines}
                    />
            </View>)
    }


    const Row1Line3 = () => {

        return (
            <View style={lineChart}>
                <MACDChart
                        dataProp={macdCPR}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'cpr'}
                        signalLines={signalLines}
                    />
            </View>)
    }

    const Row2Bar1 = () => {
        return (
            <View style={lineChart}>
                <MACDChart
                        dataProp={macdSpend}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'spend'}
                        signalLines={signalLines}
                    />
            </View>)
    }

    const Row2Mixed1 = () => {
        return (
            <View style={lineChart}>
                <MACDChart
                        dataProp={macdRegs}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'regs'}
                        signalLines={signalLines}
                    />
            </View>)
    }

    const Row2Bar2 = () => {
        return (
            <View style={lineChart}>
                <MACDChart
                        dataProp={macdROAS}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'roas'}
                        signalLines={signalLines}
                    />
            </View>)
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                            large={1}
                            
                        />
                    </View>
                    <View style={headerToggleContainer}>
                    <Text style={cumuText}>Signal Lines:</Text>
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        sx={{
                            color: 'red'
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </View>
                    {renderComponentContent()}
                </View>
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line1')}>
                    <View style={styles.lineChart}>
                    <MACDChart
                        dataProp={macdCPA}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'cpa'}
                        signalLines={signalLines}
                    />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line2')}>
                    <View style={styles.lineChart}>
                    <MACDChart
                        dataProp={macdFTD}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'ftds'}
                        signalLines={signalLines}
                    />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line3')}>
                    <View style={styles.lineChart}>
                    <MACDChart
                        dataProp={macdCPR}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'cpr'}
                        signalLines={signalLines}
                    />
                    </View>
                </Pressable>

            </View>

            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar1')}>
                <MACDChart
                        dataProp={macdSpend}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'spend'}
                        signalLines={signalLines}
                    />
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Mixed1')}>
                <MACDChart
                        dataProp={macdRegs}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'regs'}
                        signalLines={signalLines}
                    />
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar2')}>
                <MACDChart
                        dataProp={macdROAS}
                        theme={theme}
                        dateLabels={dateLabels}
                        metric1={'macdLine'}
                        metric2={'signalLine'}
                        metric3={'macdHistogram'}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                        metricValue={'roas'}
                        signalLines={signalLines}
                    />
                </Pressable>
                

            </View>
        </View>

    )

}

export default MACDPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: (windowHeight * 0.93) * 1.3,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'   
    },
    
})