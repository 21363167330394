import React, { useEffect, useState } from 'react';
import { View, Dimensions, Pressable } from 'react-native';
import { ApiGatewayS3Function } from '../../UserConfigs/ApiGateWayFunction';
import ApiGatewayFunction from '../../UserConfigs/ApiGateWayFunction';
import LoaderPercent from '../../Components/LoaderPercent';
import StudioPreviewBulding from './StudioPreviewBuilding';
import moment from 'moment-timezone';
import CloseIcon from '@mui/icons-material/Close';

import StudioPreviewGrid from './StudioPreviewGrid';
import StudioPreviewFilters from './StudioPreviewFilters';
import StudioPreviewModal from './StudioPreviewModal';

const windowWidth = Dimensions.get('window').width;

const StudioPreviewPanel = (props) => {

    const theme = props.theme
    const currentReport = props.currentReport
    const setCurrentStep = props.setCurrentStep
    const setCurrentReport = props.setCurrentReport
    const reportList = props.reportList
    const reportName = props.currentItem
    const calcFieldsAliases = currentReport.calc_fields_aliases
    const calcFields = currentReport.calc_fields
    const currentQuery = currentReport.query.query
    const layout = currentReport.layout
    const widgetLayout = currentReport.widget_layout
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const currentTable = props.currentTable
    const schemaData = props.schemaData
    const currentStep = props.currentStep
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [dimLoading, setDimLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isCumulative, setIsCumulative] = useState(false)
    const [open, setOpen] = useState(false)
    const [filter1, setFilter1] = useState(["Total"])
    const [filter2, setFilter2] = useState(["Total"])
    const [filter3, setFilter3] = useState(["Total"])

    function separateFieldsByType(dataset) {
        const dimensions = [];
        const metrics = [];
        const completeSchema = []

        dataset.forEach(field => {
            if (field.name === "timestamp" || field.name === "primary_key") return;
            if (field.type === "STRING" || field.type === "DATE" || field.type === "TIMESTAMP" || field.name === "monthsfromftd") {
                dimensions.push(field.name);
            } else {
                metrics.push(field.name);
            }
        });

        dataset.forEach(field => {
            if (field && field.name !== "timestamp" && field.name !== "primary_key") {
                completeSchema.push(field.name);
            }
        });

        return { dimensions, metrics, completeSchema};
    }


    const outputFields = separateFieldsByType(schemaData[currentTable])
    

    const [filterType1, setFilterType1] = useState(outputFields.dimensions[1])
    const [filterType2, setFilterType2] = useState(outputFields.dimensions[2])
    const [filterType3, setFilterType3] = useState(outputFields.dimensions[3])
    const filterList = [...currentQuery.dimensions, ...currentQuery.custom_dimensions_aliases]
    const [timeValue, setTimevalue] = useState('daily')


    const currentDate = moment.tz('Europe/Malta');
    const yesterday = currentDate.clone().subtract(1, 'day');
    const yesterdayString = yesterday.format('YYYY-MM-DD');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');
    const startDateString = firstDayOfYesterdaysMonthProp
    const [dateRange, setDateRange] = useState({
        "start": startDateString,
        "end": yesterdayString
    })



    const savehandler = (updateArray) => {

        setFilterType1(updateArray.value1)
        setFilterType2(updateArray.value2)
        setFilterType3(updateArray.value3)
        setTimevalue(updateArray.value4)
    }

    const cubeQuery = {

        "request_type": "query_studio",
        "query": {
            "start": dateRange.start,
            "end": dateRange.end,
            "table": currentTable,
            "date_dim": "timestamp",
            "dimensions": currentQuery.dimensions,
            "measures": currentQuery.measures,
            "custom_dimensions": currentQuery.custom_dimensions,
            "custom_dimensions_aliases": currentQuery.custom_dimensions_aliases,
            "custom_measures": currentQuery.custom_measures,
            "custom_measures_aliases": currentQuery.custom_measures_aliases,
            "filters": currentQuery.filters,
            "client_id": client_id,
            "auth0_user_id": auth0_user_id,
            "report_name": `${reportName}_cube`
        }
    }

    const dimQuery = {

        "request_type": "query_studio",
        "query": {
            "start": dateRange.start,
            "end": dateRange.end,
            "table": currentTable,
            "date_dim": "timestamp",
            "dimensions": outputFields.dimensions,
            "measures": currentQuery.measures,
            "custom_dimensions": currentQuery.custom_dimensions,
            "custom_dimensions_aliases": currentQuery.custom_dimensions_aliases,
            "custom_measures": [],
            "custom_measures_aliases": [],
            "filters": [],
            "client_id": client_id,
            "auth0_user_id": auth0_user_id,
            "report_name": `${reportName}_dim`
        }
    }


    useEffect(() => {
        const queryStart = cubeQuery.query.start;
        const queryEnd = cubeQuery.query.end;
        const queryFilters = cubeQuery.query.filters;
        const endpointStage = configArray.testingState;
        
        const cubeKey = `stack-pattern/client_preagg%2F${cubeQuery.query.client_id}%2F${cubeQuery.query.auth0_user_id}%2F${reportName}.json`
    
        const fetchData = async () => {  
          
    
          if (queryStart === dateRange.start && queryEnd === dateRange.end) {
            if (queryFilters.length === 0) {
              try {
                console.log("preAgg")
                await ApiGatewayS3Function(cubeQuery, patternAPI, support_key, support_id, setIsLoading, setConfigResponse, endpointStage, cubeKey, 'preagg')                                            
                await ApiGatewayFunction(dimQuery, patternAPI, support_key, support_id, setIsLoading, setDimResponse, endpointStage);            
                
              } catch (error) {
                console.error("Error with fechPreAgg:", error);
                ApiGatewayFunction(cubeQuery, patternAPI, support_key, support_id, setIsLoading, setConfigResponse, endpointStage);
                ApiGatewayFunction(dimQuery, patternAPI, support_key, support_id, setIsLoading, setDimResponse, endpointStage);            
              }
            } else {
                console.log("live")
                ApiGatewayFunction(cubeQuery, patternAPI, support_key, support_id, setIsLoading, setConfigResponse, endpointStage);
                ApiGatewayFunction(dimQuery, patternAPI, support_key, support_id, setIsLoading, setDimResponse, endpointStage);
              }
          } else {
            console.log("fallback")
            ApiGatewayFunction(cubeQuery, patternAPI, support_key, support_id, setIsLoading, setConfigResponse, endpointStage);
            ApiGatewayFunction(dimQuery, patternAPI, support_key, support_id, setIsLoading, setDimResponse, endpointStage);
          }
        };
        fetchData();
  }, [configArray]);


    //useEffect(() => {
    //    requestData(cubeQuery, patternAPI, support_key, support_id)
    //    dimData(dimQuery, patternAPI, support_key, support_id)
    //}, [configArray, dateRange])

    if (isLoading) {
        return (
            <LoaderPercent theme={theme} />
        );
    }

    if (!configResponse) {
        return (
            <LoaderPercent theme={theme} />
        );
    }

    if (!dimResponse) {
        return (
            <LoaderPercent theme={theme} />
        );
    }

    if (!configResponse || configResponse.statusCode === "error") {
        return (
            <StudioPreviewBulding theme={theme} />
        );
    }

    const cubeResponseBase = configResponse.data.body
    const dimResponseBase = dimResponse.data.body

    const filterDataset = (dataset, filterKey, filterValues) => {
        if (filterValues.includes('Total')) {
            // If 'Total' is in the filterValues, return the dataset unchanged
            return dataset;
        }
        // Apply filtering based on the filterKey and filterValues
        return dataset.filter(item => filterValues.includes(item[filterKey]));
    };

    const type1Filter = filterDataset(cubeResponseBase, filterType1, filter1)
    const type2Filter = filterDataset(type1Filter, filterType2, filter2)
    const cubeResponse = filterDataset(type2Filter, filterType3, filter3)

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueFilter1 = getUniqueValues(dimResponseBase, filterType1)
    const uniqueFilter2 = getUniqueValues(dimResponseBase, filterType2)
    const uniqueFilter3 = getUniqueValues(dimResponseBase, filterType3)

    const controlArray = {
        filterType1,
        setFilterType1,
        filterType2,
        setFilterType2,
        filterType3,
        setFilterType3,
        timeValue,
        setTimevalue,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
        customDimensions: filterList
    }

    return (
        <View style={{ width: '100%', height: '100%', flexDirection: 'column' }}>
            <View style={{ width: '100%', height: '7%', alignItems: 'center', justifyContent: 'center', borderColor: 'red', borderWidth: 0, flexDirection: 'row' }}>
                <View style={{ width: '95%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <StudioPreviewFilters
                        uniqueFilter1={uniqueFilter1}
                        uniqueFilter2={uniqueFilter2}
                        uniqueFilter3={uniqueFilter3}
                        isCumulative={isCumulative}
                        setIsCumulative={setIsCumulative}
                        currentMetrics={currentMetrics}
                        setOpen={setOpen}
                        filterType1={filterType1}
                        filterType2={filterType2}
                        filterType3={filterType3}
                        setFilter1List={setFilter1}
                        setFilter2List={setFilter2}
                        setFilter3List={setFilter3}
                        filter1={filter1}
                        filter2={filter2}
                        filter3={filter3}
                        theme={theme}
                        dateRange={dateRange}
                        setDateRange={setDateRange}


                    />

                </View>
                <Pressable style={{ width: '5%', height: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => setCurrentStep(0)}>
                    <CloseIcon style={{ color: theme.backgroundColor2, fontSize: windowWidth * 0.02 }} />
                </Pressable>

            </View>
            <View style={{ width: '100%', height: '93%', alignItems: 'center', justifyContent: 'center', borderColor: 'red', borderWidth: 0, }}>

                <StudioPreviewGrid
                    theme={theme}
                    layout={layout}
                    widgetLayout={widgetLayout}
                    cubeResponse={cubeResponse}
                    currentMetrics={currentMetrics}
                    calcFieldsAliases={calcFieldsAliases}
                    calcFields={calcFields}
                    isCumulative={isCumulative}
                    setCurrentReport={setCurrentReport}
                    reportList={reportList}
                    currentStep={currentStep}
                    baseMetrics={currentQuery.measures}
                />

            </View>
            <StudioPreviewModal
                theme={theme}
                open={open}
                setOpen={setOpen}
                controlArray={controlArray}
                savehandler={savehandler}
            />
        </View>
    )

}

export default StudioPreviewPanel