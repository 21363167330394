import { ContactSupportOutlined } from "@mui/icons-material";
import moment from "moment";

export default function ExecSummaryTrendsParse(cubeResponse, brand, country) {

    var dataset = cubeResponse

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    function filterDataset(dataset, key, value) {
        return dataset.filter(item => item[key] === value);
    }

    let countryFiltered, brandFiltered

    if (country === 'Total') {
        countryFiltered = dataset
    } else {
        countryFiltered = filterDataset(dataset, "country", country);
    }

    if (brand === 'Total') {
        brandFiltered = countryFiltered
    } else {
        brandFiltered = filterDataset(countryFiltered, "brand", brand);
    }

    function checkInfinite(value) {
        let output;
        if (!isFinite(value)) {
            output = 0
        } else { output = value }
        return output
    }

    var agg_result = [];

    agg_result = brandFiltered.map((obj) => {

        const month = moment.tz(obj.month, 'Europe/Paris').format('YYYY-MM')

        const output = (obj, metric) => {

            if (obj.dim === metric) {
                return obj.metric
            } else {
                return 0
            }

        }

        const ngr = output(obj, 'NGR')
        const vipDeposits = output(obj, 'VIP Deposits')
        const vipActives = output(obj, 'VIP Actives')
        const spend = output(obj, 'Spend')
        const ggr = output(obj, 'GGR')
        const ftds = output(obj, 'FTDs')
        const depositors = output(obj, 'Depositors')
        const depositCount = output(obj, 'Deposit Count')
        const depositAmount = output(obj, 'Deposit Amount')
        const cashWager = output(obj, 'Cash Wager')
        const actives = output(obj, 'Actives')
        const fixedCosts = output(obj, 'Fixed Costs')
        const brandSearch = output(obj, 'Brand Search')

        return {
            month: month,
            ngr: checkInfinite(ngr),
            vipDeposits: checkInfinite(vipDeposits),
            vipActives: checkInfinite(vipActives),
            spend: checkInfinite(spend),
            ggr: checkInfinite(ggr),
            ftds: checkInfinite(ftds),
            depositors: checkInfinite(depositors),
            depositCount: checkInfinite(depositCount),
            depositAmount: checkInfinite(depositAmount),
            cashWager: checkInfinite(cashWager),
            actives: checkInfinite(actives),
            fixedCosts: checkInfinite(fixedCosts),
            brandSearch: brandSearch
        };
    });

    let baseAgg = [];

    agg_result.reduce(function (res, value) {

        let axisValue = value.month

        if (!res[axisValue]) {
            res[axisValue] = {
                month: axisValue,
                ngr: 0,
                vipDeposits: 0,
                vipActives: 0,
                spend: 0,
                ggr: 0,
                ftds: 0,
                depositors: 0,
                depositCount: 0,
                depositAmount: 0,
                cashWager: 0,
                actives: 0,
                fixedCosts: 0,
                brandSearch: 0
            };
            baseAgg.push(res[axisValue]);
        }
        res[axisValue].ngr += parseInt(value['ngr']) || 0;
        res[axisValue].vipDeposits += parseInt(value['vipDeposits']) || 0;
        res[axisValue].vipActives += parseInt(value['vipActives']) || 0;
        res[axisValue].spend += parseInt(value['spend']) || 0;
        res[axisValue].ggr += parseInt(value['ggr']) || 0;
        res[axisValue].ftds += parseInt(value['ftds']) || 0;
        res[axisValue].depositors += parseInt(value['depositors']) || 0;
        res[axisValue].depositCount += parseInt(value['depositCount']) || 0;
        res[axisValue].depositAmount += parseInt(value['depositAmount']) || 0;
        res[axisValue].cashWager += parseInt(value['cashWager']) || 0;
        res[axisValue].actives += parseInt(value['actives']) || 0;
        res[axisValue].fixedCosts += parseInt(value['fixedCosts']) || 0;
        res[axisValue].brandSearch += parseInt(value['brandSearch']) || 0;

        return res;
    }, {});

    baseAgg = baseAgg.map((obj) => {

        const month = moment.tz(obj.month, 'Europe/Paris').format('YYYY-MM')
        const ngr = obj.ngr
        const vipDeposits = obj.vipDeposits
        const vipActives = obj.vipActives
        const spend = obj.spend
        const ggr = obj.ggr
        const ftds = obj.ftds
        const depositors = obj.depositors
        const depositCount = obj.depositCount
        const depositAmount = obj.depositAmount
        const cashWager = obj.cashWager
        const actives = obj.actives
        const fixedCosts = obj.fixedCosts
        const brandSearch = obj.brandSearch

        const arpu = (ngr / actives) || 0
        const adpu = (depositAmount / depositors) || 0
        const cpa = (spend / ftds) || 0
        const hold = (ngr / depositAmount) || 0

        return {
            month: month,
            ngr: checkInfinite(ngr),
            vipDeposits: checkInfinite(vipDeposits),
            vipActives: checkInfinite(vipActives),
            spend: checkInfinite(spend),
            ggr: checkInfinite(ggr),
            ftds: checkInfinite(ftds),
            depositors: checkInfinite(depositors),
            depositCount: checkInfinite(depositCount),
            depositAmount: checkInfinite(depositAmount),
            cashWager: checkInfinite(cashWager),
            actives: checkInfinite(actives),
            arpu: checkInfinite(arpu),
            adpu: checkInfinite(adpu),
            cpa: checkInfinite(cpa),
            hold: checkInfinite(hold),
            fixedCosts: checkInfinite(fixedCosts),
            brandSearch: checkInfinite(brandSearch)
        };
    });

    baseAgg.sort((a, b) => {
        const dateA = moment.tz(a.month, 'Europe/Paris');
        const dateB = moment.tz(b.month, 'Europe/Paris');
        return dateA - dateB;
    });

    return baseAgg;

}