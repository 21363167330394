import React, { useState, useEffect } from 'react';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ApiGatewayFunction from '../../UserConfigs/ApiGateWayFunction';
import LoaderPercent from '../../Components/LoaderPercent'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const IntegrationsFormGA4 = (props) => {

    const theme = props.theme
    const integrations = props.integrations
    const currentInt = props.currentInt
    const handleClose = props.handleClose
    const handleSave = props.handleSave
    const [isLoading, setIsLoading] = useState(false)
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const clientMapId = configArray.clientMapId
    const patternAPI = configArray.patternAPI
    const endpointStage = configArray.testingState
    const [response, setResponse] = useState(null)

    const currentIntegration = {
       "sites" : [
        12345678     
       ]
    }
    const [ga4Site, setga4Site] = useState(
        integrations?.[currentInt]?.sites ?? currentIntegration?.sites ?? []
    );
    


    const testConnection = (ga4Site) => {

        if (!ga4Site) {
            alert('Please enter a valid property ID')
        } else {

            const event_data = {
                "request_type": "ga4",
                "request_subType": "validate",
                "site": ga4Site,
                "client_id": clientMapId,
                "auth0_user_id": configArray.auth0_user_id,
                "query": {
                    "dimensions" : [{"name" : "date"}],
                    "metrics" : [{"name": "totalUsers"}],
                    "dateRanges": [{
                        "startDate": "2025-01-01",
                        "endDate": "2025-01-01",
                        "name": "userDateRange"
                        }],
                    "limit" : "100",
                    "keepEmptyRows": false
                }
            }

            ApiGatewayFunction(event_data, patternAPI, support_key, support_id, setIsLoading, setResponse, endpointStage)
        }


    }

    return (
        <View style={{ width: windowWidth * 0.4, height: '100%', maxHeight: windowHeight * 0.8, padding: '1%', overFlow: 'auto' }}>
            <FormContent
                theme={theme}
                isLoading={isLoading}
                response={response}
                testConnection={testConnection}
                ga4Site={ga4Site}
                setga4Site={setga4Site}
                handleClose={handleClose}
                handleSave={handleSave}
                currentIntegration={currentIntegration}
            />
        </View>
    )

}

const FormMap = ({item , index, updateHandler, theme}) => {
    const [siteValue, setSiteValue] = useState(item)

    const updateLocalHandler = (value, index, option) => {

            updateHandler(value, index)
            setSiteValue(value)
    

    }
    
    return (
        <View style={{width: '100%', flexDirection: 'row'}}>
        <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', borderColor: 'red', borderWidth: 1}}>
            <FormHelperText>Property ID</FormHelperText>
            <OutlinedInput
                id="outlined-search"
                onChange={e => updateLocalHandler(e.target.value, index)}
                type="search"
                value={siteValue}
                sx={{
                    width: '100%',
                    left: 0,
                    fontFamily: 'Electrolize',
                    color: theme.color,
                    backgroundColor: theme.backgroundColor,
                    textAlign: 'center'

                }}
            />

        </FormControl>
        </View>
    )
}

const FormContent = (props) => {
    const isLoading = props.isLoading
    const response = props.response
    const theme = props.theme
    const testConnection = props.testConnection
    const ga4Site = props.ga4Site
    const setga4Site = props.setga4Site
    const handleClose = props.handleClose
    const handleSave = props.handleSave
    const encodeSites = props.encodeSites
    const currentIntegration = props.currentIntegration
    const updateHandler = (item, index) => {
        const integrationsCopy = JSON.parse(JSON.stringify(ga4Site))
        integrationsCopy[index] = item
        setga4Site(integrationsCopy)
    }

    const addHandler = () => {

        const integrationsCopy = JSON.parse(JSON.stringify(ga4Site))

        const newItem = 123456
        const currentIndex = ga4Site.length
        integrationsCopy[currentIndex] = newItem
        
        setga4Site(integrationsCopy)

    }

    

    if (isLoading) {
        return <LoaderPercent theme={theme} />
    } else if (!response) {
        return (<View style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {ga4Site.map((item, index) => (
                        <FormMap
                            item={item}
                            index={index}
                            updateHandler={updateHandler}
                            key={index}
                            theme={theme}
                        />
                    ))}
            <DialogActions sx={{ backgroundColor: theme.backgroundColor, width: '100%' }} >
            <Button sx={{ color: 'white', fontWeight: 600, fontFamily: 'Electrolize' }} variant="contained"
                    onClick={() => addHandler()}
                >
                    Add Property
                </Button>
                <Button sx={{ color: 'white', fontWeight: 600, fontFamily: 'Electrolize' }} variant="contained"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>
                <Button sx={{ color: theme.backgroundColor2, fontWeight: 600, fontFamily: 'Electrolize' }} variant="outlined"
                    onClick={() => testConnection(ga4Site)}
                >
                    Test Connection
                </Button>

            </DialogActions>
        </View>)
    } else if (response.data.statusCode === 200) {
        return (<ValidatedComponent
            theme={theme}
            handleClose={handleClose}
            handleSave={handleSave}
            encodeSites={encodeSites}
            ga4Site={ga4Site}
        />)
    } else {
        return (<UnValidatedComponent
            theme={theme}
            handleClose={handleClose}
            handleSave={handleSave}
            encodeSites={encodeSites}
            fail={response.data.body.failed}
        />)
    }
}

const ValidatedComponent = (props) => {
    const theme = props.theme
    const handleClose = props.handleClose
    const handleSave = props.handleSave
    const encodeSites = props.encodeSites
    const ga4Site = props.ga4Site


    return (
        <View style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: windowWidth * 0.15 }}>
            <CheckCircleIcon style={{ color: 'green', fontSize: windowWidth * 0.1 }} />
            <FormHelperText>Connection Successful</FormHelperText>
            <DialogActions sx={{ backgroundColor: theme.backgroundColor, width: '100%' }} >
                <Button sx={{ color: 'white', fontWeight: 600, fontFamily: 'Electrolize' }} variant="contained"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>
                <Button sx={{ color: theme.backgroundColor2, fontWeight: 600, fontFamily: 'Electrolize' }} variant="outlined"
                    onClick={() => handleSave({ 'sites': ga4Site }, 'ga4')}
                >
                    Save
                </Button>

            </DialogActions>
        </View>
    )
}

const UnValidatedComponent = (props) => {
    const theme = props.theme
    const handleClose = props.handleClose
    const fail = props.fail

    return (
        <View style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: windowWidth * 0.15 }}>
            <ErrorIcon style={{ color: 'red', fontSize: windowWidth * 0.075 }} />
            <FormHelperText>Connection Unsuccessful = {fail}</FormHelperText>
            <FormHelperText>Please check the site config and ensure sufficient permissions</FormHelperText>
            <FormHelperText>Open a support ticket if the issue persists</FormHelperText>
            <DialogActions sx={{ backgroundColor: theme.backgroundColor, width: '100%' }} >
                <Button sx={{ color: 'white', fontWeight: 600, fontFamily: 'Electrolize' }} variant="contained"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </View>
    )
}



export default IntegrationsFormGA4