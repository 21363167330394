import React, { useEffect, useState } from 'react';
import { View, Dimensions, Text } from 'react-native';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import StudioCreateStep2Grid from './StudioCreateStep2Grid';
import StudioControl from '../StudioControl/StudioControl';
import { postToAPISignedUrl } from '../../UserConfigs/ApiGateway';
import postToApiGateway from '../../UserConfigs/ApiGateway';
import LoaderPercent from '../../Components/LoaderPercent';
import { styled } from '@mui/material/styles';

const windowWidth = Dimensions.get('window').width;

const StudioCreateStep2 = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const savehandler = props.savehandler
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const setCurrentStepBase = props.setCurrentStepBase
    const schemaData = props.schemaData
    const currentTable = props.currentTable

    function getFirstTwoNumericNames(data) {
        const numericNames = []; // Array to hold numeric names
    
        for (const item of data) {
            if (item.name !== 'monthsfromftd' && (item.type === "INTEGER" || item.type === "FLOAT")) {
                numericNames.push(item.name); // Add numeric name to the array
                if (numericNames.length === 2) break; // Stop once we have two
            }
        }
    
        // Extract the first and second values (or null if not found)
        const first = numericNames[0] || null;
        const second = numericNames[1] || null;
    
        // Return the results
        return { first, second, list: numericNames };
    }
    const defaultMetrics = getFirstTwoNumericNames(schemaData[currentTable]);

    const [activeitem, setActiveItem] = useState({ i: 'bar_widget_1', x: 0, y: 0, w: 2, h: 4, maxH: 20, maxW: 20, typeValue: 'bar', xAxisValue: 'date', metric1Value: defaultMetrics.first, metric2Value: defaultMetrics.second, currentFilters: [], color: theme.backgroundColor2, dateAgg: 'day', metricList: defaultMetrics.list, borderColor: 'grey', color2: theme.color, format1: 'int', format2: 'int', smoothing: true, moving_average: 'none'})
    const [activeLayoutItem, setActiveLayoutItem] = useState(0)

    const [layout, setLayout] = useState([
            { i: 'bar_widget_1', x: 0, y: 0, w: 2, h: 4, maxH: 20, maxW: 20, typeValue: 'bar', xAxisValue: 'date', metric1Value: defaultMetrics.first, metric2Value: defaultMetrics.second, currentFilters: [], color: theme.backgroundColor2, dateAgg: 'day', metricList: defaultMetrics.list, borderColor: 'grey', color2: theme.color, format1: 'int', format2: 'int', smoothing: true, moving_average: 'none' },
    
        ]);
    const [widgetLayout, setWidgetLayout] = useState([
            { i: 'bar_widget_1', x: 0, y: 0, w: 2, h: 4, maxH: 20, maxW: 20, typeValue: 'bar', xAxisValue: 'date', metric1Value: defaultMetrics.first, metric2Value: defaultMetrics.second, currentFilters: [], color: theme.backgroundColor2, dateAgg: 'day', metricList: defaultMetrics.list, borderColor: 'grey', color2: theme.color, format1: 'int', format2: 'int', smoothing: true, moving_average: 'none' },
    
        ]);
    const [reportName, setReportName] = useState(`Dashboard_${Math.floor(10000000 + Math.random() * 90000000)}`)
    const [customMetrics, setCustomMetrics] = useState([])
    const [customMetricsAliases, setCustomMetricsAliases] = useState([])
    const [customDims, setCustomDims] = useState([])
    const [customDimsAliases, setCustomDimsAliases] = useState([])
    const [customDimArray, setCustomDimArray] = useState([])
    const [customMetricValues, setCustomMetricValues] = useState([])
    const [customMetricFormats, setCustomMetricFormats] = useState([])
    const [customMetricFunctions, setCustomMetricFunctions] = useState([])
    const [customMetricArray, setCustomMetricArray] = useState([])
    const [calcFields, setCalcFields] = useState([])
    const [calcFieldsAliases, setCalcFieldsAliases] = useState([])
    const [calcFieldsFormats, setCalcFieldsFormats] = useState([])
    const [calcFieldsFunctions, setCalcFieldsFunctions] = useState([])
    const [metricList, setMetricList] = useState(["spend", "ftds"])
    const currentSchema = schemaData[currentTable] || []
    const [updateQuery, setUpdateQuery] = useState(null)

    function extractUniqueValues(data, outputFields) {
        const xAxisValues = new Set();
        const metricValues = new Set();
    
        data.forEach(item => {
            // Add xAxisValue to the set if it is in outputFields.dimensions
            if (item.xAxisValue && outputFields.dimensions.includes(item.xAxisValue)) {
                xAxisValues.add(item.xAxisValue);
            }
    
            // Add metric1Value and metric2Value to the set if they are in outputFields.metrics
            if (item.metric1Value && outputFields.metrics.includes(item.metric1Value)) {
                metricValues.add(item.metric1Value);
            }
            if (item.metric2Value && outputFields.metrics.includes(item.metric2Value)) {
                metricValues.add(item.metric2Value);
            }
    
            // Add all valid values from metricList to the set if they are in outputFields.metrics
            if (item.metricList && Array.isArray(item.metricList)) {
                item.metricList.forEach(metric => {
                    if (outputFields.metrics.includes(metric)) {
                        metricValues.add(metric);
                    }
                });
            }
            if (calcFields.length > 0) {
                const sqlText = JSON.stringify(calcFields)
                const metricRegex = /[a-zA-Z_][a-zA-Z0-9_]*/g;
                const extractedMetrics = sqlText.match(metricRegex) || [];

                // Check if any extracted metric matches the allowed metrics
                const matchingMetrics = extractedMetrics.filter(metric =>
                    outputFields.metrics.includes(metric)
            );

            matchingMetrics.forEach(metric => metricValues.add(metric));
            }
        });
    
        return {
            uniqueXAxisValues: Array.from(xAxisValues),
            uniqueMetricValues: Array.from(metricValues),
        };
    }

    function separateFieldsByType(dataset) {
        const dimensions = [];
        const metrics = [];
        const completeSchema = []

        dataset.forEach(field => {
            if (field.name === "timestamp" || field.name === "primary_key") return;
            if (field.type === "STRING" || field.type === "DATE" || field.type === "TIMESTAMP" || field.name === "monthsfromftd") {
                dimensions.push(field.name);
            } else {
                metrics.push(field.name);
            }
        });

        dataset.forEach(field => {
            if (field && field.name !== "timestamp" && field.name !== "primary_key") {
                completeSchema.push(field.name);
            }
        });

        return { dimensions, metrics, completeSchema};
    }


    const outputFields = separateFieldsByType(currentSchema)

    

    const { uniqueXAxisValues, uniqueMetricValues } = extractUniqueValues(widgetLayout, outputFields);

    const customArray = {
        customMetrics,
        setCustomMetrics,
        customMetricsAliases,
        setCustomMetricsAliases,
        customDims,
        setCustomDims,
        customDimsAliases,
        setCustomDimsAliases,
        customDimArray,
        setCustomDimArray,
        customMetricArray,
        setCustomMetricArray,
        currentMetrics,
        currentTable,
        customMetricValues,
        setCustomMetricValues,
        customMetricFormats,
        setCustomMetricFormats,
        customMetricFunctions,
        setCustomMetricFunctions,
        calcFields,
        setCalcFields,
        calcFieldsAliases,
        setCalcFieldsAliases,
        calcFieldsFormats,
        setCalcFieldsFormats,
        calcFieldsFunctions,
        setCalcFieldsFunctions,
        metricList,
        setMetricList
    }

    const currentDate = moment.tz('Europe/Malta');
    const lastModified = currentDate.format('YYYY-MM-DD');
    const yesterday = currentDate.clone().subtract(1, 'day');
    const yesterdayString = yesterday.format('YYYY-MM-DD');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');
    const startDateString = firstDayOfYesterdaysMonthProp

    
    const dimArrayBase = {
        dims: outputFields.dimensions,
        metrics: outputFields.metrics
    }
    const [dimArray, setDimArray] = useState(dimArrayBase)

    //let xAxisFallback;
    //if (uniqueXAxisValues.length === 0) {
    //    xAxisFallback = ['timestamp']
    //} else {
    //    xAxisFallback = uniqueXAxisValues
    //}
    
    const cubeQuery = {

        "request_type": "query_studio_create",
        "query": {
          "start": startDateString,
          "end": yesterdayString,
          "table": currentTable,
          "date_dim": "timestamp",
          "dimensions": outputFields.dimensions,
          "measures": uniqueMetricValues,
          "custom_dimensions": customDims,
          "custom_dimensions_aliases": customDimsAliases,
          "custom_measures": customMetricValues,
          "custom_measures_aliases": customMetricsAliases,
          "filters": [],
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": reportName
        }
    }
    
    const requestData = async (event_data, support_url, support_key, support_id) => {
            setIsLoading(true)
            setConfigResponse(null); // Clear previous response
            const requestType = event_data.request_type
            const endpointStage = configArray.testingState
            const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
            try {
                // First, get the signed URL
                const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
        
                // Send the POST request to the signed URL
                const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
                setConfigResponse(result)
                setIsLoading(false)
            } catch (error) {
                console.error("Error posting to Lambda:", error);
            } finally {
                //setSaveLoading(false);
            }
        };

    useEffect(() => {
            requestData(cubeQuery, patternAPI, support_key, support_id)
          }, [configArray, customDimsAliases, customMetricsAliases, updateQuery])

          if (isLoading) {
            return (
              <LoaderPercent theme={theme} />
            );
          }
          
          if (!configResponse) {
            return (
              <LoaderPercent theme={theme} />
            );
          }

    const cubeResponse = configResponse.data.body

    const clickHandler = (type) => {

        if (type === 'close') {
            setCurrentStepBase(0)
        } else if (type === 'save') {
            const event_data = {
                "client": client_lower,
                "client_id": client_id,
                "table": currentTable,
                "auth0_user_id": configArray.auth0_user_id,
                "last_modified": lastModified,
                "layout": layout,
                "widget_layout": widgetLayout,
                "query": cubeQuery,
                "calc_fields": calcFields,
                "calc_fields_aliases": calcFieldsAliases,
                "calc_fields_format": calcFieldsFormats,
                "calc_fields_functions": calcFieldsFunctions,
                "custom_dims": customDims,
                "custom_dims_aliases": customDimsAliases,
                "report_name": reportName

            }
            // save custom dimensions and metrics to config
            savehandler(event_data, reportName)
        } 

    }

    const ControlPanel = () => {
        if (!activeitem){
            return null
        } else {
            return <StudioControl
                theme={theme}
                currentSchema={currentSchema}
                activeitem={activeitem}
                layout={layout}
                setLayout={setLayout}
                reportName={reportName}
                setReportName={setReportName}
                widgetLayout={widgetLayout}
                setWidgetLayout={setWidgetLayout}
                activeLayoutItem={activeLayoutItem}
                setActiveLayoutItem={setActiveLayoutItem}
                outputFields={outputFields}
                customArray={customArray}
                dimArray={dimArray}
                setDimArray={setDimArray}
                configArray={configArray}
                currentTable={currentTable}
                savehandler={savehandler}
                setUpdateQuery={setUpdateQuery}
                defaultMetrics={defaultMetrics}
                currentMetrics={currentMetrics}

            />
        }
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.backgroundColor,
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        width: '75%',
        backgroundColor: theme.backgroundColor2,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        },
    }));

    return (
        <View style={{ width: '100%', height: '100%', flexDirection: 'column' }}>
            <View style={{ width: '100%', height: '90%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ width: '75%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems:'center', justifyContent: 'center' }}>

                    <StudioCreateStep2Grid 
                        theme={theme}
                        currentSchema={currentSchema}
                        activeitem={activeitem}
                        setActiveItem={setActiveItem}
                        layout={layout}
                        setLayout={setLayout}
                        widgetLayout={widgetLayout}
                        setWidgetLayout={setWidgetLayout}
                        setActiveLayoutItem={setActiveLayoutItem}
                        activeLayoutItem={activeLayoutItem}
                        cubeResponse={cubeResponse}
                        currentMetrics={currentMetrics}
                        calcFieldsAliases={calcFieldsAliases}
                        calcFields={calcFields}
                        defaultMetrics={defaultMetrics}
                    />

                </View>
                <View style={{ width: '25%', height: '100%', padding: '1%', overflow: 'auto', borderColor: 'grey', borderWidth: 1, alignItems: 'center' }}>
                    <ControlPanel />
                </View>
            </View>
            <View style={{ width: '100%', height: '10%', flexDirection: 'row', alignItems: 'end', borderColor: 'grey', borderWidth: 1, justifyContent: 'center' }}>
                <View style={{ width: '75%', justifyContent: 'center', borderColor: 'grey', borderWidth: 1, height: '100%', padding: '1%'}}>

                    <Text 
                        style={{fontSize: windowWidth * 0.01, fontFamily: 'Electrolize', color: theme.color, fontWeight: 600}}
                    >
                        Current Table: {currentTable}
                    </Text>

                </View>
                <View style={{width: '12.5%', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                    <ColorButton
                        onClick={() => clickHandler('close')}
                        theme={theme}
                        >
                            CLOSE

                    </ColorButton>
                </View>
                <View style={{width: '12.5%', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                    <ColorButton
                        onClick={() => clickHandler('save')}
                        theme={theme}>
                            SAVE & CLOSE
                    </ColorButton>
                </View>

            </View>

        </View>
    )
}

export default StudioCreateStep2