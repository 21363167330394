import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ExecSummaryTrendsParse from './ExecSummaryTrendsParse';
import BarChart from '../../ChartsDesktop/BarChart';

const windowHeight = Dimensions.get('window').height;

const ExecSummaryTrendsPanels = (props) => {

    const theme = props.theme
    const dataProp = props.dataProp
    const aggResult = dataProp.aggResult
    const currentMetrics = props.currentMetrics

    const [brand, setBrand] = useState('Total')
    const [country, setCountry] = useState('Total')

    const parseData = ExecSummaryTrendsParse(aggResult, brand, country)

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const dateLabels = getUniqueValues(parseData, 'month')

    const BarRow = (props) => {

        const metric1 = props.metric1
        const metric2 = props.metric2
        const metric3 = props.metric3


        return (
            <View style={{ flexDirection: 'row', width: '100%', height: windowHeight * 0.29, alignitems: 'center', justifyContent: 'center' }}>
                <View style={{ width: '33.3333%', height: '100%', alignitems: 'center', justifyContent: 'center', padding: '1%', borderColor: 'grey', borderWidth: 1 }}>
                    <BarChart
                        dataProp={parseData}
                        labels={dateLabels}
                        theme={theme}
                        metric={metric1}
                        timeFrame={'monthly'}
                        client={'glitnor'}
                        currentMetrics={currentMetrics}
                    />

                </View>
                <View style={{ width: '33.3333%', height: '100%', alignitems: 'center', justifyContent: 'center', padding: '1%', borderColor: 'grey', borderWidth: 1 }}>
                    <BarChart
                        dataProp={parseData}
                        labels={dateLabels}
                        theme={theme}
                        metric={metric2}
                        timeFrame={'monthly'}
                        client={'glitnor'}
                        currentMetrics={currentMetrics}
                    />

                </View>
                <View style={{ width: '33.3333%', height: '100%', alignitems: 'center', justifyContent: 'center', padding: '1%', borderColor: 'grey', borderWidth: 1 }}>
                    <BarChart
                        dataProp={parseData}
                        labels={dateLabels}
                        theme={theme}
                        metric={metric3}
                        timeFrame={'monthly'}
                        client={'glitnor'}
                        currentMetrics={currentMetrics}
                    />

                </View>

            </View>
        )
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.backgroundColor,
        //fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor2,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        },
    }));

    const InvertColorButton = styled(Button)(({ theme }) => ({
        color: theme.color,
        //fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor,
        '&:hover': {
            backgroundColor: theme.backgroundColor,
        },
    }));

    const BrandButton = ({item, theme}) => {
        if (item === brand) {
            return (
                <ColorButton variant="contained"
                    theme={theme}
                    onClick={() => setBrand(item)}
                >
                    {item}
                </ColorButton>
            )
        } else {
            return (
                <InvertColorButton variant="contained"
                    theme={theme}
                    onClick={() => setBrand(item)}
                >
                    {item}
                </InvertColorButton>
            )
        }
    }

    const CountryButton = ({item, theme}) => {
        if (item === country) {
            return (
                <ColorButton variant="contained"
                    theme={theme}
                    onClick={() => setCountry(item)}
                >
                    {item}
                </ColorButton>
            )
        } else {
            return (
                <InvertColorButton variant="contained"
                    theme={theme}
                    onClick={() => setCountry(item)}
                >
                    {item}
                </InvertColorButton>
            )
        }
    }

    return (
        <View style={{ width: '100%', height: '100%', borderColor: 'grey', borderWidth: 1 }}>
            <View style={{ width: '100%', height: windowHeight * 0.06, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '1%' }}>
                <View style={{width: '40%', height: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', padding: '1%', gap: '1%'}}>
                    <BrandButton item={'Total'} theme={theme} />
                    <BrandButton item={'Lucky'} theme={theme} />
                    <BrandButton item={'Happy'} theme={theme} />
                    <BrandButton item={'Flax'} theme={theme} />
                </View>
                <View style={{width: '60%', height: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', padding: '1%', gap: '1%'}}>
                    <CountryButton item={'Total'} theme={theme} />
                    <CountryButton item={'SWE'} theme={theme} />
                    <CountryButton item={'FIN'} theme={theme} />
                    <CountryButton item={'CHL'} theme={theme} />
                </View>
            

            

            </View>
            <BarRow metric1={'spend'} metric2={'fixedCosts'} metric3={'ngr'} />
            <BarRow metric1={'arpu'} metric2={'actives'} metric3={'ftds'} />
            <BarRow metric1={'depositCount'} metric2={'depositors'} metric3={'adpu'} />
            <BarRow metric1={'ggr'} metric2={'cpa'} metric3={'hold'} />
            <BarRow metric1={'vipDeposits'} metric2={'vipActives'} metric3={'brandSearch'} />

        </View>

    )


}

export default ExecSummaryTrendsPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    }
})