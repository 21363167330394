import React, { useState, useEffect } from 'react';
import { View, Dimensions } from 'react-native'
import LoaderPercent from '../Components/LoaderPercent';
import { ApiGatewayS3Function } from '../UserConfigs/ApiGateWayFunction';
import ApiGatewayFunction from '../UserConfigs/ApiGateWayFunction';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const AuthGoogle = (props) => {

    const configArray = props.configArray;
    const theme = props.theme;
    const support_id = configArray.support_id;
    const support_key = configArray.support_key;
    const clientMapId = configArray.clientMapId;
    const client_id = configArray.clientMapId;
    const auth0_user_id = configArray.auth0_user_id;
    const patternAPI = configArray.patternAPI;

    const [configResponse, setConfigResponse] = useState(null);
    const [saveResponse, setSaveResponse] = useState({statusCode: 400})
    const [isLoading, setIsLoading] = useState(false);

    const testingState = configArray.testingState
    let currentDomain;
    if (testingState === 'testing') {
        currentDomain = 'http://localhost:3000/testing'
    } else if (testingState === 'prod') {
        currentDomain = 'https://pattern-inc.io/testing'
    }

    const cubeQuery = {
        "request_type": "creds",
    }

    const getAuthParamsFromHash = () => {
        const hash = window.location.hash.substring(1); // Remove the '#' symbol
        const params = new URLSearchParams(hash);

        const authParams = {};
        for (const [key, value] of params.entries()) {
            authParams[key] = value;
        }

        return authParams;
    };

    // Example Usage
    const authParams = getAuthParamsFromHash();

    useEffect(() => {
        const endpointStage = configArray.testingState

        const credsKey = `stack-pattern/client_googleOAuth%2F${client_id}%2F${auth0_user_id}.json`

        const fetchData = async () => {

            try {
                await ApiGatewayS3Function(cubeQuery, patternAPI, support_key, support_id, setIsLoading, setConfigResponse, endpointStage, credsKey, 'preagg')


            } catch (error) {
                console.error("No Creds Found:", error);
                setConfigResponse({})
            }

        };

        fetchData();
    }, [configArray]);

   

    //const config = configResponse.data.body

    useEffect(() => {
        if (configResponse) {
            if (authParams.access_token) {
                const configCopy = JSON.parse(JSON.stringify(configResponse.data.body))
                configCopy['google_auth'] = authParams
                const endpointStage = configArray.testingState
        
                const saveQuery = {
                    "request_type": "google_auth",
                    "request_sub_type": "update",
                    "client_id": clientMapId,
                    "auth0_user_id": auth0_user_id,
                    "config": configCopy
                }        
        
                
                    ApiGatewayFunction(saveQuery, patternAPI, support_key, support_id, setIsLoading, setSaveResponse, endpointStage);
                    
            }
        }
    }, [configResponse]);

    useEffect(() => {

        if (saveResponse?.statusCode === 200) {
            window.location.href = currentDomain;
        }

        

    }, [saveResponse])

    

    return (
        <View style={{ width: windowWidth, height: windowHeight }}>
            <LoaderPercent theme={theme} />
        </View>
    )




}

export default AuthGoogle