import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS, } from '@mui/x-data-grid-premium';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function TableLarge(props) {

  LicenseInfo.setLicenseKey('3dd7f0e5176fd2661730ecde2785f53fTz0xMDU4OTgsRT0xNzY4NDM1MTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');  
  const dataProp = props.dataProp
  const colNum = props.colNum
  const columnWidth = props.columnWidth
  const theme = props.theme
  const currentSettings = props.currentSettings
  const aggregationModel = props.aggregationModel
  const setAggregationModel = props.setAggregationModel
  const currentMetrics = props.currentMetrics
 
  
  let modifiedSettings;

  if (currentSettings === null) {
    modifiedSettings = ["brand", "kw_type", "channel", "ctr" , "clicks", "spend", "regs", "impressions" , "ftds"];
  } else {
    modifiedSettings = [...currentSettings];
    
    if (!modifiedSettings.includes('brand') && !modifiedSettings.includes('channel') && !modifiedSettings.includes('kw_type') && !modifiedSettings.includes('date')) {
      modifiedSettings.unshift('brand');
    }
  }

  function calculateTotal (spend, ftd) {
    return "TOTAL"
    }

  function calculateCPA (spend, ftd) {
    const cpa =  (spend / ftd)

    return Math.round(cpa)
}

function calculateCPC (spend, clicks) {
    const cpc =  (spend / clicks)

    return Math.round(cpc)
}

function calculateCPR (spend, regs) {
    const cpr =  (spend / regs)

    return Math.round(cpr)
}

function calculateCTR (clicks, impressions) {
  const ctr =  (clicks / impressions)
  return parseFloat(ctr).toFixed(6)
  }

const cpa = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            ftd += value.ftd || 0;
        }
      });
      return calculateCPA(spend, ftd);
    },
    columnTypes: ['number'],
  };

  const cpc = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, clicks: row.clicks }),
    apply: ({ values }) => {
      let spend = 0;
      let clicks = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            clicks += value.clicks || 0;
        }
      });
      return calculateCPC(spend, clicks);
    },
    columnTypes: ['number'],
  };

  const cpr = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, regs: row.regs }),
    apply: ({ values }) => {
      let spend = 0;
      let regs = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            regs += value.regs || 0;
        }
      });
      return calculateCPR(spend, regs);
    },
    columnTypes: ['number'],
  };

  const ctr = {
    label: '',
    getCellValue: ({ row }) => ({ clicks: row.clicks, impressions: row.impressions }),
    apply: ({ values }) => {
      let clicks = 0;
      let impressions = 0;
      values.forEach((value) => {
        if (value) {
            clicks += value.clicks || 0; // Adds 0 if value.spend is null or NaN
            impressions += value.impressions || 0;
        }
      });
      return calculateCTR(clicks, impressions);
    },
    columnTypes: ['number'],
  };

  const total = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            ftd += value.ftd || 0;
        }
      });
      return calculateTotal(spend, ftd);
    },
    columnTypes: ['number'],
  };


  
  function aggregateData(inputArray, dataset) {
    const dimensions = inputArray.filter(key => !["impressions", "clicks", "spend", "regs", "ftds", "cpa", "cpr", "cpc" , "ctr"].includes(key));
    const values = ["impressions", "clicks", "spend", "regs", "ftds"];
    
    const result = {};
    
    dataset.forEach((value) => {
      const dimensionKey = dimensions.map(key => value[key]).join("_");
    
      if (!result[dimensionKey]) {
        result[dimensionKey] = { ...dimensions.reduce((obj, key) => {
          obj[key] = value[key];
          return obj;
        }, {}), ...values.reduce((obj, key) => {
          obj[key] = 0; // Initialize all value fields to 0
          return obj;
        }, {}) };
      }
    
      values.forEach((key) => {
        result[dimensionKey][key] += parseInt(value[key]);
      });
    });
    
    return Object.values(result);
  }
  
  const aggregatedResult = aggregateData(modifiedSettings, dataProp);

  var finalResult = aggregatedResult

  finalResult = finalResult.map((obj) => {
    const spend = parseFloat(obj.spend);
    const ftds = parseInt(obj.ftds);
    const regs = parseInt(obj.regs)
    const clicks = parseInt(obj.clicks)
    const impressions = parseInt(obj.impressions)
    const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
    const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
    const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
    const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;

    return {
      ...obj,
      cpa: parseInt(cpa),
      cpr : parseInt(cpr),
      ctr : parseFloat(ctr).toFixed(6),
      cpc : parseFloat(cpc.toFixed(2))
    };
  });


  const columnVisibility = {
    id : modifiedSettings.includes('id'),
    impressions : modifiedSettings.includes('impressions'),
    clicks : modifiedSettings.includes('clicks'),
    spend : modifiedSettings.includes('spend'),
    regs : modifiedSettings.includes('regs'),
    ftds : modifiedSettings.includes('ftds'),
    cpa : modifiedSettings.includes('cpa'),
    cpr : modifiedSettings.includes('cpr'),
    ctr : modifiedSettings.includes('ctr'),
    cpc : modifiedSettings.includes('cpc'),
  }

  const valueFormat = (value, field) => {
    if (field === 'cpa' || field === 'spend'|| field === 'cpr'|| field === 'cpc') {
        return `€${value}`;
    } else if (field === 'ctr') {
      const percent = (parseFloat(value) * 100).toFixed(4);
      return `${percent}%`;
    }
    return value;
};


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

  const idRow = [{
    field: 'id', 
    headerName: 'ID', 
    maxWidth: 5, 
    groupable: false,
    headerAlign: 'center', 
    headerClassName: 'table-header', 
    align: 'center' , 
    cellClassName : 'super-app-theme--cell'}]

    const minMaxValues = {};
    Object.keys(finalResult[0]).forEach((key) => {
      const values = finalResult.map((row) => row[key]);
      minMaxValues[key] = { min: Math.min(...values), max: Math.max(...values) };
    });

    let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else {responseText = windowHeight * 0.017}

  const labels = Object.keys(finalResult[0]).map((key) => {
    let newLabel;
      if (key === 'ftds') {
        newLabel = currentMetrics.primaryMetric
      } else if (key === 'regs') {
        newLabel = currentMetrics.secondaryMetric
      } else if (key === 'kw_type') {
        newLabel = currentMetrics.primaryDimensionString.toLowerCase()
      } else {newLabel = key}
    return { 
    field: key, 
    headerName: newLabel, 
    //minWidth: windowWidth * columnWidth * 1.25, 
    flex: 1, 
    headerAlign: 'center', 
    headerClassName: 'table-header', 
    groupable: false,
    align: 'center' ,
    type: 'number',
    renderCell: (params) => {
      if (params.aggregation) {
        // Check if it's an aggregate cell
        const isAggregateCell = params.aggregation.hasCellUnit;

        if (isAggregateCell) {

          const isBrandCell = params.field === 'brand';
          // Apply custom styling for aggregate cell
          return (
            <div
              style={{
                  fontWeight: 'bold', // Add your custom font weight
                  display: 'flex', // Apply flex styles to center content vertically/horizontally
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%', // Ensure the cell takes up the full height
                  width: '100%',
                  color: theme.color,
                  paddingLeft: 0,
                  fontFamily: 'Electrolize',
                  fontSize: responseText,

              }}


            >
              {valueFormat(params.formattedValue, params.field)}
            </div>
          );
        }
      }

      // Render normal cell content
      return (
        <div>
        {valueFormat(params.formattedValue, params.field)}
        </div>
        );
    },
    cellClassName: (params) => {
      if (params.value == null) {
        return '';
      }

      const { min, max } = minMaxValues[key];
      return clsx('super-app', {
        c10: params.value >= (max - min) * 0.9,
        c20: params.value >= (max - min) * 0.8 && params.value < (max - min) * 0.9,
        c30: params.value >= (max - min) * 0.7 && params.value < (max - min) * 0.8,
        c40: params.value >= (max - min) * 0.6 && params.value < (max - min) * 0.7,
        c50: params.value >= (max - min) * 0.5 && params.value < (max - min) * 0.6,
        c60: params.value >= (max - min) * 0.4 && params.value < (max - min) * 0.5,
        c70: params.value >= (max - min) * 0.3 && params.value < (max - min) * 0.4,
        c80: params.value >= (max - min) * 0.2 && params.value < (max - min) * 0.3,
        c90: params.value >= (max - min) * 0.1 && params.value < (max - min) * 0.2,
        c100: params.value >= (max - min) * 0 && params.value < (max - min) * 0.1,
        
      });
    },};
  });  
  const columnFix = [...idRow, ...labels];

  const rows = finalResult.map((row, index) => ({ id: index + 1, ...row, cellClassName: 'table-cells'}));

  const columns = columnFix

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport sx={{color: theme.color}}/>
      </GridToolbarContainer>
    );
  }

  

    let responseRow;

    if (windowWidth < 1281) {
      responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
      responseRow = windowHeight * 0.05
    } else {responseRow = windowHeight * 0.04}

    let responsePad;

    if (windowWidth < 1281) {
      responsePad = '0%'
    } else if (windowWidth > 2000) {
      responsePad = '5%'
    } else {responsePad = '0%'}

    

    
 
  return (
    <Box
    style={{
      width: '100%',
      fontSize: 50,
      color: theme.color,
      fontFamily : 'Electrolize',
      alignItems:'center', justifyContent: 'center',

      
    }}
      sx={{
        width: '100%',

        '& .MuiDataGrid-row' : {backgroundColor : theme.backgroundColor},
        '& .MuiTablePagination-root' : {color: theme.color},
        '& .table-header': { headerAlign: 'center', align : 'center' , fontFamily : 'Electrolize' , color: theme.color, fontSize: responseText * 1.2},
        '& .table-cells': { align: 'center' , fontFamily : 'Electrolize'},
        '& .super-app': { align: 'center' , fontFamily : 'Electrolize', color: theme.color, fontSize: responseText * 1.1},
        '& .super-app.c10': {
          backgroundColor: theme.gradc10,
          color: 'white',
          fontWeight: '600',
          fontFamily : 'Electrolize',
          fontSize: responseText
          
        },
        '& .super-app.c20': {
          backgroundColor: theme.gradc20,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize',
          fontSize: responseText
        },
        '& .super-app.c30': {
          backgroundColor: theme.gradc30,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize',
          fontSize: responseText
        },
        '& .super-app.c40': {
          backgroundColor: theme.gradc40,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize',
          fontSize: responseText
        },
        '& .super-app.c50': {
          backgroundColor: theme.gradc50,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize',
          fontSize: responseText
        },
        '& .super-app.c60': {
          backgroundColor: theme.gradc60,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize',
          fontSize: responseText
        },
        '& .super-app.c70': {
          backgroundColor: theme.gradc70,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize',
          fontSize: responseText
        },
        '& .super-app.c80': {
          backgroundColor: theme.gradc80,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize',
          fontSize: responseText
        },
        '& .super-app.c90': {
          backgroundColor: theme.gradc90,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize',
          fontSize: responseText
        },
        '& .super-app.c100': {
          backgroundColor: theme.gradc100,
          fontFamily : 'Electrolize',
          fontWeight: '100',
          color: 'white',
          fontSize: responseText
        },
        '& .super-app.cell-brand' : {
          backgroundColor: theme.backgroundColor2,
          color: theme.backgroundColor2
        },


      }}
    >
      <DataGridPremium 
        rows={rows}
        columns={columns} 
        rowHeight={responseRow}
        columnVisibilityModel={columnVisibility}
        slots={{ toolbar: CustomToolbar }}
        aggregationModel={aggregationModel}
        onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
        aggregationFunctions={{
                ...GRID_AGGREGATION_FUNCTIONS,
                cpa,
                cpc,
                cpr,
                ctr,
                total
                }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: parseInt(colNum) },
          },

        }}

        />
    </Box>
  );
}