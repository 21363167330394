import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const testingState = 'prod'

let authDomain, redirectUri, fmKey, datagrid;
if (testingState === 'testing') {
  authDomain = 'dev-hldt17c2gz1xk402.eu.auth0.com'
  redirectUri = 'http://localhost:3000/home'
  fmKey = "Z7KJ-10AD5U-2Y5Y43-4V1L1H-6T686U-303W71-5M0B5C-0O4Q3M-4D6B0U-5I1R1U-383R2L-73"
  datagrid = '3dd7f0e5176fd2661730ecde2785f53fTz0xMDU4OTgsRT0xNzY4NDM1MTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
} else if (testingState === 'prod') {
  authDomain = 'www.pattern-inc.io'
  redirectUri = 'https://pattern-inc.io/home'
  fmKey = "Z7Z5-107C2M-4F5E22-2F005M-6Y3C60-180H6E-005Z69-5G256W-2X6W2K-564Y3Q-1X00"
  datagrid = '3dd7f0e5176fd2661730ecde2785f53fTz0xMDU4OTgsRT0xNzY4NDM1MTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
}

root.render(
    <Auth0Provider
      domain={authDomain}
      //domain={prodDomain}
      clientId={process.env.REACT_APP_CUBE_CLIENT_ID}
      authorizationParams={{
        redirect_uri: redirectUri
      }}
    >
      <App fmKey={fmKey} testingState={testingState} datagrid={datagrid} />
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
