import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import IntegrationsModal from './IntegrationsModal';
import IntegrationsActiveCard from './IntegrationsActiveCard';
import PreviewGsc from './IntegrationsGSC/PreviewGsc';
import PreviewGa4 from './IntegrationsGA4/PreviewGA4';

import gscImage from '../Integrations/IntegrationsIImages/gsc.png';
import ga4Image from '../Integrations/IntegrationsIImages/ga4.png';
import { LicenseInfo } from '@mui/x-license-pro';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const IntegationsPanel = (props) => {

    LicenseInfo.setLicenseKey('3dd7f0e5176fd2661730ecde2785f53fTz0xMDU4OTgsRT0xNzY4NDM1MTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');
    const theme = props.theme
    const configArray = props.configArray
    const panelContent = props.panelContent
    const setPanelContent = props.setPanelContent
    const titleProvider = props.titleProvider
    const integrations = configArray.integrations || {}
    
    const [currentInt, setCurrentInt] = useState(null)
    const [open, setOpen] = useState(false)
    const activeIntegrations = Object.keys(integrations)

    const originalIntegrations = {
        "ga4" : {},
        "gsc": {}
    }


    function splitIntegrationLists(originalIntegrations, inputArray) {
        const matched = Object.keys(originalIntegrations)
            .filter(key => inputArray.includes(key))
            .reduce((acc, key) => {
                acc[key] = originalIntegrations[key]; // Store full child object
                return acc;
            }, {});
    
        const notMatched = Object.keys(originalIntegrations)
            .filter(key => !inputArray.includes(key))
            .reduce((acc, key) => {
                acc[key] = originalIntegrations[key]; // Store full child object
                return acc;
            }, {});
    
        return { matched, notMatched };
    }

    const editFunction = (item) => {
        setCurrentInt(item)
        setOpen(true)
    }

    const testFunction = (item) => {
        setCurrentInt(item)
        setPanelContent(item)
    }

    

    const imageProvider = (item) => {
        if (item === 'gsc') {
            return gscImage
        } else if (item === 'ga4') {
            return ga4Image
        } else {
            return gscImage
        }
    }

    const { matched, notMatched } = splitIntegrationLists(originalIntegrations, activeIntegrations);
    
    const ActiveComponent = ({item, index, isMatched, value}) => {

        return (
            <View style={{width: '25%', height: '100%', gap: '1%', padding: '1%'}} key={index}>
                <IntegrationsActiveCard
                    image={imageProvider(item)}
                    text={titleProvider(item)}  
                    theme={theme}
                    editFunction={editFunction}
                    testFunction={testFunction}
                    itemValues={value}
                    isMatched={isMatched}
                    item={item}
                />
            </View>
        )

    }

    const IntegrationsHome = () => {

        return (
            <View style={styles.container}>
            <View style={{width: '100%', height: '10%', alignItems: 'center', justifyContent:'start', padding: '1%', borderColor: 'grey', borderWidth: 1, backgroundColor: theme.backgroundColor2}}>
                <Text style={{textAlign: 'start', width: '100%', fontSize: windowWidth * 0.02, fontFamily: 'Electrolize', fontWeight: 600, color: 'white'}} >
                    Active Integrations
                </Text>
            </View>
            <View style={{width: '100%', height: '30%', alignItems: 'center', justifyContent:'start', flexDirection: 'row', overFlow: 'auto'}}>
            {Object.entries(matched).map(([key, value], index) => (
                <ActiveComponent item={key} key={index} isMatched={true} value={value} />
            ))}
            </View>
            <View style={{width: '100%', height: '10%', alignItems: 'center', justifyContent:'start', padding: '1%', borderColor: 'grey', borderWidth: 1, backgroundColor: theme.backgroundColor2}}>
                <Text style={{textAlign: 'start', width: '100%', fontSize: windowWidth * 0.02, fontFamily: 'Electrolize', fontWeight: 600, color: 'white'}} >
                    Inactive Integrations
                </Text>
            </View>
            <View style={{width: '100%', height: '30%', alignItems: 'center', justifyContent:'start', flexDirection: 'row', overFlow: 'auto'}}>
            {Object.entries(notMatched).map(([key, value], index) => (
                <ActiveComponent item={key} key={index} isMatched={false} value={value}/>
            ))}
            </View>
            <IntegrationsModal
                open={open}
                setOpen={setOpen}
                theme={theme}
                configArray={configArray}
                currentInt={currentInt}
                integrations={integrations}
                titleProvider={titleProvider}
            />            

        </View>
        )
    }

    const PanelContent = () => {
        if (panelContent === 'home') {
            return (
                <IntegrationsHome/>
            )
        } else if (panelContent === 'gsc') {
            return (
                <PreviewGsc
                    theme={theme}
                    configArray={configArray}
                    currentInt={currentInt}
                    integrations={integrations}
                    titleProvider={titleProvider}
                />
            )
        } else if (panelContent === 'ga4') {
            return (
                <PreviewGa4
                    theme={theme}
                    configArray={configArray}
                    currentInt={currentInt}
                    integrations={integrations}
                    titleProvider={titleProvider}
                />
            )
        }
    }

    return (
        <PanelContent/>        
    )

}

export default IntegationsPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',

    }
})