import { postToAPISignedUrl } from "./ApiGateway";
import postToApiGateway from "./ApiGateway";
import { postToAPIpreagg } from "./ApiGateway";
import { signPreagg } from "./ApiGateway";

const ApiGatewayFunction = async (event_data, support_url, support_key, support_id, setLoading, setResponse, state) => {
    setLoading(true)
    setResponse(null);
    console.log(event_data)
    const requestType = event_data.request_type
    const endpointStage = state
    const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
    try {
        // First, get the signed URL
        const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);

        // Send the POST request to the signed URL
        const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
        setResponse(result)
        setLoading(false)
    } catch (error) {
        console.error("Error posting to Lambda:", error);
    } finally {
        //setSaveLoading(false);
    }
};

export const ApiGatewayPreaggFunction = async (event_data, support_url, support_key, support_id, setLoading, setResponse, state, client_id) => {

    setLoading(true)
    setResponse(null);
    const endpointStage = state
    const requestType = 'preagg'
    const reportId = event_data.query.report_name
    const key = `stack-pattern/default_preagg%2F${client_id}%2F${reportId}.json`
    const patternEndpoint = `${support_url}/${endpointStage}/${requestType}/${key}`
    try {
        // First, get the signed URL
        const signedUrl = await signPreagg(event_data, patternEndpoint, support_key, support_id);

        // Send the POST request to the signed URL
        const result = await postToAPIpreagg(signedUrl, event_data, "GET");
        setResponse(result)
        setLoading(false)
    } catch (error) {
        console.error("Error posting to Lambda:", error);
    }

}

export const ApiGatewayConfigFunction = async (event_data, support_url, support_key, support_id, setResponse, state) => {

    //setLoading(true)
    setResponse(null);
    const endpointStage = state
    const requestType = 'preagg'
    const auth0_user_id = event_data.auth0_user_id
    const client_id = event_data.client_id
    const key = `stack-pattern/client_configs%2F${client_id}%2Fuser_${client_id}_${auth0_user_id}.json`
    const patternEndpoint = `${support_url}/${endpointStage}/${requestType}/${key}`
    try {
        // First, get the signed URL
        const signedUrl = await signPreagg(event_data, patternEndpoint, support_key, support_id);

        // Send the POST request to the signed URL
        const result = await postToAPIpreagg(signedUrl, event_data, "GET");
        setResponse(result)
        //setLoading(false)
    } catch (error) {
        console.error("Error posting to Lambda:", error);
    }
}

export const ApiGatewayCommentaryFunction = async (event_data, support_url, support_key, support_id, setLoading, setResponse, state) => {

    setLoading(true)
    setResponse(null);
    const endpointStage = state
    const requestType = 'preagg'
    const client_id = event_data.client
    const key = `stack-pattern/client_commentary%2Fdaily%2F${client_id}_daily_commentary.json`
    
    const patternEndpoint = `${support_url}/${endpointStage}/${requestType}/${key}`
    try {
        // First, get the signed URL
        const signedUrl = await signPreagg(event_data, patternEndpoint, support_key, support_id);

        // Send the POST request to the signed URL
        const result = await postToAPIpreagg(signedUrl, event_data, "GET");
        setResponse(result)
        setLoading(false)
    } catch (error) {
        console.error("Error posting to Lambda:", error);
    }
}

export const ApiGatewayS3Function = async (event_data, patternAPI, support_key, support_id, setLoading, setResponse, state, key, requestType) => {

    setLoading(true)
    setResponse(null);
   
    const patternEndpoint = `${patternAPI}/${state}/${requestType}/${key}`
    try {
        // First, get the signed URL
        const signedUrl = await signPreagg(event_data, patternEndpoint, support_key, support_id);

        // Send the POST request to the signed URL
        const result = await postToAPIpreagg(signedUrl, event_data, "GET");
        setResponse(result)
        setLoading(false)
    } catch (error) {
        console.error("Error posting to Lambda:", error);
    }
}

export default ApiGatewayFunction