import React, { useState, useEffect } from 'react';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ApiGatewayFunction from '../../UserConfigs/ApiGateWayFunction';
import LoaderPercent from '../../Components/LoaderPercent'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const IntegrationsFormGSC = (props) => {

    const theme = props.theme
    const integrations = props.integrations
    const currentInt = props.currentInt
    const handleClose = props.handleClose
    const handleSave = props.handleSave
    const [isLoading, setIsLoading] = useState(false)
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const clientMapId = configArray.clientMapId
    const patternAPI = configArray.patternAPI
    const endpointStage = configArray.testingState
    const [response, setResponse] = useState(null)

    const currentIntegration = {
       "sites" : [
        {
            "site": "xyz",
            "isDomainProperty" : "no"
        }       
       ]
    }
    const [gscSite, setGscSite] = useState(
        integrations?.[currentInt]?.sites ?? currentIntegration?.sites ?? []
    );


    const processUserInput = (data) => {
        return data.map(({ site, isDomainProperty }) => {
            if (isDomainProperty === "yes") {
                return `sc-domain:${site}`; // Do not encode
            } else {
                return site.replace(/:/g, "%3A").replace(/\//g, "%2F"); // Encode `:` and `/`
            }
        });
    };
  
    

    let encodeSites;
    if (!gscSite) {
        encodeSites = gscSite
    } else {
        encodeSites = processUserInput(gscSite)        
    }

    const testConnection = (gscSite) => {

        if (!gscSite) {
            alert('Please enter a valid site and property type')
        } else {

            const event_data = {
                "request_type": "gcs",
                "request_subType": "validate",
                "site": encodeSites,
                "client_id": clientMapId,
                "auth0_user_id": configArray.auth0_user_id,
                "query": {
                    "startDate": "2025-01-01",
                    "endDate": "2025-01-01",
                    "dimensions": ["country"]
                }
            }

            ApiGatewayFunction(event_data, patternAPI, support_key, support_id, setIsLoading, setResponse, endpointStage)
        }


    }

    return (
        <View style={{ width: windowWidth * 0.4, height: '100%', maxHeight: windowHeight * 0.8, padding: '1%', overFlow: 'auto' }}>
            <FormContent
                theme={theme}
                isLoading={isLoading}
                response={response}
                testConnection={testConnection}
                gscSite={gscSite}
                setGscSite={setGscSite}
                handleClose={handleClose}
                handleSave={handleSave}
                encodeSites={encodeSites}
                currentIntegration={currentIntegration}
            />
        </View>
    )

}

const FormMap = ({item , index, updateHandler, theme, options}) => {
    const [siteValue, setSiteValue] = useState(item.site)
    const [optionValue, setOptionValue] = useState(item.isDomainProperty)

    const updateLocalHandler = (value, index, option) => {
        if (option === 'site') {
            const currentItem = {
                "site": value,
                "isDomainProperty" : optionValue
            }
            updateHandler(currentItem, index)
            setSiteValue(value)
        } else if (option === 'isDomainProperty') {
            const currentItem = {
                "site": siteValue,
                "isDomainProperty" : value
            }
            updateHandler(currentItem, index)
            setOptionValue(value)
        }       

    }
    
    return (
        <View style={{width: '100%', flexDirection: 'row'}}>
        <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', borderColor: 'red', borderWidth: 1}}>
            <FormHelperText>Site</FormHelperText>
            <OutlinedInput
                id="outlined-search"
                onChange={e => updateLocalHandler(e.target.value, index, 'site')}
                type="search"
                value={siteValue}
                sx={{
                    width: '100%',
                    left: 0,
                    fontFamily: 'Electrolize',
                    color: theme.color,
                    backgroundColor: theme.backgroundColor,
                    textAlign: 'center'

                }}
            />

        </FormControl>
        <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', borderColor: 'red', borderWidth: 1}}>
            <FormHelperText>Domain Property?</FormHelperText>
            <Select
                labelId="demo-dialog-select-label1"
                id="demo-dialog-select"
                value={optionValue}
                sx={{ fontFamily: 'Electrolize'}}

                onChange={(event) => updateLocalHandler(event.target.value, index, 'isDomainProperty')}
                input={<OutlinedInput label="" />}
            >
                {options}
            </Select>
        </FormControl>
        </View>
    )
}

const FormContent = (props) => {
    const isLoading = props.isLoading
    const response = props.response
    const theme = props.theme
    const testConnection = props.testConnection
    const gscSite = props.gscSite
    const setGscSite = props.setGscSite
    const handleClose = props.handleClose
    const handleSave = props.handleSave
    const encodeSites = props.encodeSites
    const currentIntegration = props.currentIntegration

    const optionsList = ['yes', 'no']

    const options = optionsList.map((item, index) => (
        <MenuItem key={index} value={item} sx={{ fontFamily: 'Electrolize' }}>
            {item}
        </MenuItem>
    ));

    const updateHandler = (item, index) => {
        const integrationsCopy = JSON.parse(JSON.stringify(gscSite))
        integrationsCopy[index] = item
        setGscSite(integrationsCopy)
    }

    const addHandler = () => {

        const integrationsCopy = JSON.parse(JSON.stringify(gscSite))

        const newItem = {
            "site": "abc",
            "isDomainProperty" : "no"
        }
        const currentIndex = gscSite.length
        integrationsCopy[currentIndex] = newItem
        
        setGscSite(integrationsCopy)

    }

    

    if (isLoading) {
        return <LoaderPercent theme={theme} />
    } else if (!response) {
        return (<View style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {gscSite.map((item, index) => (
                        <FormMap
                            item={item}
                            index={index}
                            updateHandler={updateHandler}
                            key={index}
                            theme={theme}
                            options={options}
                        />
                    ))}
                <FormHelperText>URL needs to exactly match your GSC property, including any trailing slashes</FormHelperText>
            <DialogActions sx={{ backgroundColor: theme.backgroundColor, width: '100%' }} >
            <Button sx={{ color: 'white', fontWeight: 600, fontFamily: 'Electrolize' }} variant="contained"
                    onClick={() => addHandler()}
                >
                    Add Site
                </Button>
                <Button sx={{ color: 'white', fontWeight: 600, fontFamily: 'Electrolize' }} variant="contained"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>
                <Button sx={{ color: theme.backgroundColor2, fontWeight: 600, fontFamily: 'Electrolize' }} variant="outlined"
                    onClick={() => testConnection(gscSite)}
                >
                    Test Connection
                </Button>

            </DialogActions>
        </View>)
    } else if (response.data.statusCode === 200) {
        return (<ValidatedComponent
            theme={theme}
            handleClose={handleClose}
            handleSave={handleSave}
            encodeSites={encodeSites}
            gscSite={gscSite}
        />)
    } else {
        return (<UnValidatedComponent
            theme={theme}
            handleClose={handleClose}
            handleSave={handleSave}
            encodeSites={encodeSites}
            fail={response.data.body.failed}
        />)
    }
}

const ValidatedComponent = (props) => {
    const theme = props.theme
    const handleClose = props.handleClose
    const handleSave = props.handleSave
    const encodeSites = props.encodeSites
    const gscSite = props.gscSite


    return (
        <View style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: windowWidth * 0.15 }}>
            <CheckCircleIcon style={{ color: 'green', fontSize: windowWidth * 0.1 }} />
            <FormHelperText>Connection Successful</FormHelperText>
            <DialogActions sx={{ backgroundColor: theme.backgroundColor, width: '100%' }} >
                <Button sx={{ color: 'white', fontWeight: 600, fontFamily: 'Electrolize' }} variant="contained"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>
                <Button sx={{ color: theme.backgroundColor2, fontWeight: 600, fontFamily: 'Electrolize' }} variant="outlined"
                    onClick={() => handleSave({ 'sites': gscSite }, 'gsc')}
                >
                    Save
                </Button>

            </DialogActions>
        </View>
    )
}

const UnValidatedComponent = (props) => {
    const theme = props.theme
    const handleClose = props.handleClose
    const fail = props.fail

    return (
        <View style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: windowWidth * 0.15 }}>
            <ErrorIcon style={{ color: 'red', fontSize: windowWidth * 0.075 }} />
            <FormHelperText>Connection Unsuccessful = {fail}</FormHelperText>
            <FormHelperText>Please check the site config and ensure sufficient permissions</FormHelperText>
            <FormHelperText>Open a support ticket if the issue persists</FormHelperText>
            <DialogActions sx={{ backgroundColor: theme.backgroundColor, width: '100%' }} >
                <Button sx={{ color: 'white', fontWeight: 600, fontFamily: 'Electrolize' }} variant="contained"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </View>
    )
}



export default IntegrationsFormGSC