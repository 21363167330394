import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import { ApiGatewayS3Function } from '../../UserConfigs/ApiGateWayFunction.js';
import LoadingComponent from '../../Components/Loader.js';
import NoDataContainer from '../../Components/NoDataContainer.js';
import ExecSummaryTrendsPanels from './ExecSummaryTrendsPanels.js';

const ExecSummaryTrendsPanel = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const patternAPI = configArray.patternAPI
    const currentMetrics = props.currentMetrics
    const [configResponse, setConfigResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {   
        const key = `stack-pattern/client_customReports%2F${client_id}%2F${client_lower}_exexSummary_trends.json`
        const event_data = {
            "request_type": 'preagg'
        }

        ApiGatewayS3Function(event_data, patternAPI, support_key, support_id, setIsLoading, setConfigResponse, configArray.testingState, key, 'preagg');
}, [configArray]);

useEffect(() => {
    WebFont.load({
        google: {
            families: ['Droid Sans', 'Electrolize']
        }
    });
}, []);

if (isLoading) {
    return (
        <LoadingComponent theme={theme} />
    );
}

if (!configResponse) {
    return (
        <LoadingComponent theme={theme} />
    );
}

const cubeResponse = configResponse.data.body

if (cubeResponse.length === 0) {
    return (
        <NoDataContainer
            theme={theme}
        />
    )
}



const dataProp = {
    aggResult: cubeResponse
}

return (
    <View style={styles.container}>
        <ExecSummaryTrendsPanels
            theme={theme}
            dataProp={dataProp}
            currentMetrics={currentMetrics}
        />
    </View>

)

}

export default ExecSummaryTrendsPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})