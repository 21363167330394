import React, { useEffect, useState } from 'react'
import {
    View,
    StyleSheet,
    Dimensions,
    Text
} from 'react-native'
import WebFont from 'webfontloader';
import ParseCumulative from './CubeFunctions/ParseCumulative';
//import DrawerList from './Drawers/DrawerList';
import HomeArea from './HomeArea';
import HomeFAB from './HomeFAB';
import HomeCommentary from './HomeCommentary';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const HomePanelV3 = (props) => {

    const theme = props.theme
    const data = props.data
    const clientID = props.clientID
    const budgetArray = props.budgetArray
    const currentMetrics = props.currentMetrics
    const commentaryData = props.commentaryData
    const actual_spend = budgetArray[0].actual_spend
    const budget = budgetArray[0].budget
    const yesterdaySpend = budgetArray[0].yesterday_spend
    const currentDom = budgetArray[0].current_dom
    const currentFtd = budgetArray[0].ftd
    const currentReg = budgetArray[0].reg
    const ngr = budgetArray[0].ngr
    
    const dailyTarget = Math.round(actual_spend / currentDom)
    //const dailyTargetFormat = Math.round(actual_spend / currentDom).toLocaleString()
    const currentCPA = Math.round(actual_spend / currentFtd).toLocaleString()
    const currentCPR = Math.round(actual_spend / currentReg).toLocaleString()
    const currentSpend = Math.round(actual_spend).toLocaleString()
    const currentBudget = Math.round(budget).toLocaleString()
    const currentNGR = Math.round(ngr).toLocaleString()
    const yestRate = Math.round((parseFloat(yesterdaySpend) / dailyTarget) * 100) || 0
    const spendRate = Math.round((parseFloat(actual_spend) / parseFloat(budget)) * 100) || 0

    function checkInfinite (value) {
        let output;
        if (!isFinite(value)) {
            output = "-"
        } else { output = value}
        return output
    }

    const cumuDates = props.cumuDates
    const start = cumuDates.start
    const end = cumuDates.end

    const cumulativeData = ParseCumulative(data, start, end)
    const lastMonth = cumulativeData.lastMonth
    const thisMonth = cumulativeData.thisMonth

    const configBase = {
        mainGraph: 'cumulative',
        panel1: 'mtdSpendRate',
        panel2: 'yestSpendRate',
        shortcuts: ['Daily Summary', 
                    'Weekly Summary', 
                    'Monthly Summary',
                    'Budget Summary',
                    'Dashboard Builder',
                    'GA4 Summary',
                    'GA4 Attribution',
                    'PPT Builder',
                    'Cohorts ROAS'],
        mainIcon: 'dashBuilder'
    }

    
    if (!JSON.parse(localStorage.getItem("homeConfig"))) {
        localStorage.setItem("homeConfig", JSON.stringify(configBase))
    }

    const storedConfig = localStorage.getItem("homeConfig");
    const storedLayout = JSON.parse(storedConfig)
    const panel1Value = storedLayout['panel1'] || 'mtdSpendRate'
    const panel2Value = storedLayout['panel2'] || 'yestSpendRate'

    let panel1Output, panel2Output;

    if (panel1Value === 'mtdSpendRate') {
        panel1Output = checkInfinite(spendRate)+"%"
    } else if (panel1Value === 'yestSpendRate') {
        panel1Output = checkInfinite(yestRate)+"%"
    } else if (panel1Value === 'currentSpend') {
        panel1Output = "€"+currentSpend
    } else if (panel1Value === 'currentFtd') {
        panel1Output = currentFtd
    } else if (panel1Value === 'currentReg') {
        panel1Output = currentReg
    } else if (panel1Value === 'currentCPA') {
        panel1Output = "€"+currentCPA
    } else if (panel1Value === 'currentCPR') {
        panel1Output = "€"+currentCPR
    } else if (panel1Value === 'currentBudget') {
        panel1Output = "€"+currentBudget
    } else if (panel1Value === 'currentNgr') {
        panel1Output = "€"+currentNGR
    } else {panel1Output = 0}

    if (panel2Value === 'mtdSpendRate') {
        panel2Output = checkInfinite(spendRate)+"%"
    } else if (panel2Value === 'yestSpendRate') {
        panel2Output = checkInfinite(yestRate)+"%"
    } else if (panel2Value === 'currentSpend') {
        panel2Output = "€"+currentSpend
    } else if (panel2Value === 'currentFtd') {
        panel2Output = currentFtd
    } else if (panel2Value === 'currentReg') {
        panel2Output = currentReg
    } else if (panel2Value === 'currentCPA') {
        panel2Output = "€"+currentCPA
    } else if (panel2Value === 'currentCPR') {
        panel2Output = "€"+currentCPR
    } else if (panel2Value === 'currentBudget') {
        panel2Output = "€"+currentBudget
    } else if (panel2Value === 'currentNgr') {
        panel2Output = "€"+currentNGR
    } else {panel2Output = 0}

    console.log()

    let panel1Title, panel2Title;

    if (panel1Value === 'mtdSpendRate') {
        panel1Title = 'MTD Spend Rate'
    } else if (panel1Value === 'yestSpendRate') {
        panel1Title = 'Yesterday Spend Rate'
    } else if (panel1Value === 'currentSpend') {
        panel1Title = 'MTD Spend'
    } else if (panel1Value === 'currentFtd') {
        panel1Title = `MTD ${currentMetrics.primaryMetricString}`
    } else if (panel1Value === 'currentReg') {
        panel1Title = `MTD ${currentMetrics.secondaryMetricString}`
    } else if (panel1Value === 'currentCPA') {
        panel1Title = 'Current CPA'
    } else if (panel1Value === 'currentCPR') {
        panel1Title = 'Current CPR'
    } else if (panel1Value === 'currentBudget') {
        panel1Title = 'MTD Budget'
    } else if (panel1Value === 'currentNgr') {
        panel1Title = `MTD ${currentMetrics.primaryRevenueString}`
    } else {panel1Title = "Test"}

    if (panel2Value === 'mtdSpendRate') {
        panel2Title = 'MTD Spend Rate'
    } else if (panel2Value === 'yestSpendRate') {
        panel2Title = 'Yesterday Spend Rate'
    } else if (panel2Value === 'currentSpend') {
        panel2Title = 'MTD Spend'
    } else if (panel2Value === 'currentFtd') {
        panel2Title = `MTD ${currentMetrics.primaryMetricString}`
    } else if (panel2Value === 'currentReg') {
        panel2Title = `MTD ${currentMetrics.secondaryMetricString}`
    } else if (panel2Value === 'currentCPA') {
        panel2Title = 'Current CPA'
    } else if (panel2Value === 'currentCPR') {
        panel2Title = 'Current CPR'
    } else if (panel2Value === 'currentBudget') {
        panel2Title = 'MTD Budget'
    } else if (panel2Value === 'currentNgr') {
        panel2Title = `MTD ${currentMetrics.primaryRevenueString}`
    } else {panel2Title = "Test"}

    const [selectedMetric, setSelectedMetric] = useState('spend')

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const dateLabels = getUniqueValues(lastMonth, 'day_of_month')
    

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    let rateColor;
    if (spendRate < 90 || spendRate > 110 ) {
        rateColor = 'red'
    } else { rateColor = 'green'}

    let yestRateColor;
    if (parseInt(yestRate) < 90 || parseInt(yestRate) > 110 ) {
        yestRateColor = 'red'
    } else { yestRateColor = 'green'}

    let dynamicText;
    if (windowWidth > 2000) {
        dynamicText = 200
    } else if (windowWidth > 1281) {
        dynamicText = 85
    } else { dynamicText = 75}

    let dynamicTextSmall;
    if (windowWidth > 2000) {
        dynamicTextSmall = 50
    } else if (windowWidth > 1281) {
        dynamicTextSmall = 20
    } else { dynamicTextSmall = 20}



    const graphPanel = {
        height: '100%',
        width: '66.666666%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'grey',
        borderWidth: 1,
        flexDirection: 'column'
    }

    const metricPanel = {
        height: '100%',
        width: '33.33333%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'column'
    }

    const metric = {
        height: '50%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'grey',
        borderWidth: 1
    }

    const graph = {
        height: '90%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.color,
        borderWidth: 0,
        padding: '1%'
    }

    const selectContainer = {
        height: '10%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'row',
        flex: 1
    }

    const fabContainer = {
        width: '20%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3%'

    }
 

    return (
        <View style={styles.container}>
            <View style={styles.topPanel}>
                <View style={graphPanel}>
                    <View style={graph}>
                    <HomeArea
                        lastMonth={lastMonth}
                        thisMonth={thisMonth}
                        dateLabels={dateLabels}
                        theme={theme}
                        metric1={selectedMetric}
                        currentMetrics={currentMetrics}
                    />

                    </View>
                    <View style={selectContainer}>
                        <View style={fabContainer}>
                            <HomeFAB
                                buttonText={'spend'}
                                theme={theme}
                                selectHandler={setSelectedMetric}
                                dynamicTextSmall={dynamicTextSmall}
                                currentMetrics={currentMetrics}
                            />
                        </View>
                        <View style={fabContainer}>
                            <HomeFAB
                                buttonText={'ftds'}
                                theme={theme}
                                selectHandler={setSelectedMetric}
                                dynamicTextSmall={dynamicTextSmall}
                                currentMetrics={currentMetrics}
                            />
                        </View>
                        <View style={fabContainer}>
                            <HomeFAB
                                buttonText={'ngr'}
                                theme={theme}
                                selectHandler={setSelectedMetric}
                                dynamicTextSmall={dynamicTextSmall}
                                currentMetrics={currentMetrics}
                            />
                        </View>
                        <View style={fabContainer}>
                            <HomeFAB
                                buttonText={'cpa'}
                                theme={theme}
                                selectHandler={setSelectedMetric}
                                dynamicTextSmall={dynamicTextSmall}
                                currentMetrics={currentMetrics}
                            />
                        </View>
                        <View style={fabContainer}>
                            <HomeFAB
                                buttonText={'clicks'}
                                theme={theme}
                                selectHandler={setSelectedMetric}
                                dynamicTextSmall={dynamicTextSmall}
                                currentMetrics={currentMetrics}
                            />
                        </View>
                        

                    </View>

                </View>
                <View style={metricPanel}>
                    <View style={metric}>
                        <Text style={{
                            color: rateColor, 
                            fontSize: dynamicText * 0.75,
                            fontFamily: 'Electrolize',
                            filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                            }}>
                            {panel1Output}
                        </Text>
                        <Text style={{
                            color: theme.color, 
                            fontSize: dynamicTextSmall,
                            fontFamily: 'Electrolize',
                            fontWeight: 'bold'
                            }}>
                            {panel1Title}
                        </Text>
                    </View>
                    <View style={metric}>
                        <Text style={{
                            color: yestRateColor, 
                            fontSize: dynamicText * 0.75,
                            fontFamily: 'Electrolize',
                            filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                            }}>
                            {panel2Output}
                        </Text>
                        <Text style={{
                            color: theme.color, 
                            fontSize: dynamicTextSmall,
                            fontFamily: 'Electrolize',
                            fontWeight: 'bold'
                            }}>
                            {panel2Title}
                        </Text>  
                    </View>

                </View>

            </View>
            <View style={styles.bottomPanel}>
                <HomeCommentary
                    theme={theme}
                    commentaryData={commentaryData}
                    clientID={clientID}
                    currentMetrics={currentMetrics}
                />

            </View>

        </View>
    )

}

export default HomePanelV3

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',

    },
    topPanel: {
        height: '66.66666%',
        width: '100%',
        borderColor: 'grey',
        borderWidth: 0,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1%',
        paddingLeft: '2%',
        paddingRight: '2%'
    },
    bottomPanel: {
        height: '33.33333%',
        width: '100%',
        borderColor: 'grey',
        borderWidth: 0,
        flexDirection: 'column',
        paddingLeft: '2%',
        paddingRight: '2%',
        paddingBottom: '1%'
    },
    alertPanel: {
        height: '100%',
        width: '32%',
        borderColor: 'grey',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1%',
        

    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        fontFamily: 'Electrolize'
    }
})