import React, { useEffect } from 'react';
import { View , StyleSheet , Dimensions, Text} from 'react-native'
import WebFont from 'webfontloader';
import BuildIcon from '@mui/icons-material/Build';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const StudioPreviewBulding = (props) => {

    const theme = props.theme

    let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.04
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.035
    } else {responseText = windowHeight * 0.035}

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    return (
        <View style={[styles.container,{backgroundColor: theme.backgroundColor}]}>
            <View style={{height: '25%', width:'50%', alignItems: 'center', justifyContent: 'center'}}>
                <BuildIcon style={{fontSize: 100, color: theme.backgroundColor2}}/>
            </View>
            <Text style={[styles.text, {color: theme.color, fontSize: responseText}]}>
                Report is still compiling...
            </Text>
            <Text style={[styles.text, {color: theme.color, fontSize: responseText}]}>
                Please hit refresh
            </Text>
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      paddingTop: 0,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%'

    },
    loaderContainer: {
        borderWidth: 0,
        borderColor: 'blue',
    },
    text: {
        fontFamily: 'Electrolize',
        fontSize: 30
    }
})

export default StudioPreviewBulding

