import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { GridToolbarContainer,
        GridToolbarColumnsButton,
        GridToolbarFilterButton,
        GridToolbarDensitySelector,
        GridToolbarExport 
       } from '@mui/x-data-grid';
import { Dimensions, Pressable } from 'react-native'
import WebFont from 'webfontloader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Download from '../../Components/Download';
import { DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS, } from '@mui/x-data-grid-premium';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function TableGSC(props) {

  const dataProp = props.dataProp
  const currentDim = props.currentDim
  const theme = props.theme

  const [contextMenu, setContextMenu] = useState(null);
  
    const handleContextMenu = (event) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          :
            null,
      );
    };
  
    const handleClose = () => {
      setContextMenu(null);
    };



  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false
  })

  const [aggregationModel, setAggregationModel] = useState({
    [currentDim]: 'total',
    impressions: 'sum',
    clicks: 'sum',
    position: 'posAv',
    ctr: 'ctr',
    totalUsers: 'sum',
    newUsers: 'sum',            
    sessions: 'sum',
    bounceRate: 'bounce',
    activeUsers: 'sum',
    eventCount: 'sum',
    keyEvents: 'sum',
    averageSessionDuration: 'sessionAv',
  });

 

  let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else {responseText = windowHeight * 0.017}

    let responseRow;

    if (windowWidth < 1281) {
      responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
      responseRow = windowHeight * 0.05
    } else {responseRow = windowHeight * 0.04}


  const valueFormat = (value, field) => {
    if (field === 'spend_rate' ||field === 'ctr' || field === 'bounceRate'  ) {
        const percent = Math.round(value * 100); // Convert to percent and round to 2 decimal places
        return `${percent}%`;
    } else if (field === 'cpa' || field === 'cpc') {
      if (value.includes(',')) {
        const valueBase = value.replace(/,/g, '');
        const returnValue = (parseFloat(valueBase)).toFixed(0);
        
        return `€ ${returnValue}`;
      } else if (field === 'position') {
        return (parseFloat(value)).toFixed(2)
      } else {
        const returnValue = (parseFloat(value)).toFixed(0);
        return `€${returnValue}`;
      }

      
    } else if (field === 'cpa' || field === 'spend'|| field === 'ngr'|| field === 'ftd_ngr'|| field === 'target_daily' || field === 'cpc') {
        return `€${value}`;
    } else
    return value;
};

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
    record.id = uniqueIdCounter++;
    });

    const labelObject = dataProp[0] || []

    const labelsBase = Object.keys(labelObject).map((key , index) => {

        return { 
        field: key, 
        headerName: key, 
        //minWidth: windowWidth * columnWidth * 1.25, 
        flex: 1, 
        headerAlign: 'center', 
        headerClassName: 'table-header', 
        align: 'center',
        type: 'number',
        renderCell: (params) => {
            const { value, field } = params;
            const heatmapColor = calculateHeatmapColor(value, { field });
            if (index < 1) {
                return (
                  <div
                    className="heatmap-cell"
                    style={{
                      backgroundColor: heatmapColor,
                      padding: '7%',
                      color: theme.color,
                      width: '100%',
                      fontSize: responseText * 0.8,
                      fontFamily: 'Electrolize'
                    }}
                  >
                    {valueFormat(params.formattedValue, params.field)}
                  </div>
                );
              } else if (params.aggregation) {
                return (
                    <div
                        className="heatmap-cell-aggregated"
                        style={{
                            fontWeight: 'bold', // Add your custom font weight
                            display: 'flex', // Apply flex styles to center content vertically/horizontally
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%', // Ensure the cell takes up the full height
                            width: '100%',
                            color: theme.color,
                            paddingLeft: 0,
                            fontFamily: 'Electrolize',
                            fontSize: responseText * 0.8,
                        }}
                    >
                        {valueFormat(params.formattedValue, params.field)}
                    </div>
                );
            } else return (
                <div
                  className="heatmap-cell"
                  style={{
                    backgroundColor: heatmapColor,
                    padding: '10%',
                    width: '100%' ,
                    fontSize: responseText * 0.8,
                    fontFamily: 'Electrolize'
                    
                  }}
                >
                  {valueFormat(params.formattedValue, params.field)}
                </div>
              );
            },
        
        };
      });  

      const reorderFields = (data) => {
        const order = [
            "impressions",
            "clicks",
            "ctr",
            "position",
        ];
    
        // Separate the dimension fields (not in the order array) and metric fields (in the order array)
        const dimensionFields = [];
        const metricFields = [];
    
        data.forEach(item => {
            if (order.includes(item.field)) {
                metricFields.push(item);
            } else {
                dimensionFields.push(item);
            }
        });
    
        // Sort the metric fields based on the order array
        metricFields.sort((a, b) => order.indexOf(a.field) - order.indexOf(b.field));
    
        // Return concatenated array: dimensions first, then sorted metrics
        return [...dimensionFields, ...metricFields];
    };

    const labels = reorderFields(labelsBase)

    const columnRanges = {};
    Object.keys(labelObject).forEach((column) => {
    const values = dataProp.map((record) => record[column]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
  const { min, max } = columnRanges[column.field];
  const range = max + 1- min;

// Define input colors for the heatmap in RGB format
  const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
  const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;

  const relativeValue = (value - min) / range;
  const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
  const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
  const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

  return `rgba(${red},${green},${blue},0.9)`;
}

function calculateCtr (clicks, impressions) {

  const ctr = (clicks / impressions).toFixed(2)
  return ctr
}

function calculatePos (count, position) {

    const pos = (position / count).toFixed(2)
    return pos
  }


function calculateTotal (spend, ftd) {
  return "TOTAL"
  }


const ctr = {
  label: '',
  getCellValue: ({ row }) => ({ clicks: row.clicks, impressions: row.impressions }),
  apply: ({ values }) => {
    let clicks = 0;
    let impressions = 0;
    values.forEach((value) => {
      if (value) {
        clicks += value.clicks || 0; // Adds 0 if value.spend is null or NaN
        impressions += value.impressions || 0;
      }
    });
    return calculateCtr(clicks, impressions);
  },
  columnTypes: ['number'],
};

const posAv = {
    label: '',
    getCellValue: ({ row }) => ({ position: row.position }),
    apply: ({ values }) => {
      let count = 0;
      let position = 0;
      values.forEach((value) => {
        if (value) {
            count += 1; // Adds 0 if value.spend is null or NaN
            position += value.position || 0;
        }
      });
      return calculatePos(count, position);
    },
    columnTypes: ['number'],
  };

  const bounce = {
    label: '',
    getCellValue: ({ row }) => ({ bounce: row.bounceRate }),
    apply: ({ values }) => {
      let count = 0;
      let bounce = 0;
      values.forEach((value) => {
        if (value) {
            count += 1; // Adds 0 if value.spend is null or NaN
            bounce += value.bounce || 0;
        }
      });
      return calculatePos(count, bounce);
    },
    columnTypes: ['number'],
  };

  const sessionAv = {
    label: '',
    getCellValue: ({ row }) => ({ averageSessionDuration: row.averageSessionDuration }),
    apply: ({ values }) => {
      let count = 0;
      let averageSessionDuration = 0;
      values.forEach((value) => {
        if (value) {
            count += 1; // Adds 0 if value.spend is null or NaN
            averageSessionDuration += value.averageSessionDuration || 0;
        }
      });
      return calculatePos(count, averageSessionDuration);
    },
    columnTypes: ['number'],
  };

  const total = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            ftd += value.ftd || 0;
        }
      });
      return calculateTotal(spend, ftd);
    },
    columnTypes: ['number'],
  };

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', height: '100%', width: '100%'}}>
    <Box
    style={{
      width: '100%',
      height : '100%', 
      fontSize: 50,
      color: 'black',
      fontFamily : 'Electrolize',
      marginTop: '0%'
      
    }}
      sx={{
        '& .table-header': { 
          headerAlign: 'center', 
          align : 'center' , 
          fontFamily : 'Electrolize' , 
          color: theme.color, 
          fontSize: responseText, },
          '& .MuiDataGrid-row' : {backgroundColor : theme.backgroundColor},
          '& .MuiTablePagination-root' : {color: 'grey'},
        width: '100%'}}
    >
      <DataGridPremium 
        rows={dataProp}
        columns={labels}
        rowHeight={responseRow}
        pagination
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        aggregationModel={aggregationModel}
                        onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
                        aggregationFunctions={{
                        ...GRID_AGGREGATION_FUNCTIONS,
                        ctr,
                        total,
                        posAv,
                        bounce,
                        sessionAv
                        }}

        initialState={{
          //pagination: {
            //paginationModel: { pageSize: 5 }           
          //},
        sorting: {
        sortModel: [{ field: currentDim, sort: 'desc' }],
        },
        }}
        
        />
    </Box>
    <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
        </Menu>
    </div>
  );
}