import moment from "moment";

export default function ParseGA4 (data, dim) {

    const agg_result = data.map((obj) => {
        const dimValue = obj['dimension']
        const totalUsers = obj.totalUsers
        const newUsers = obj.newUsers            
        const sessions = obj.sessions
        const bounceRate = obj.bounceRate
        const activeUsers = obj.activeUsers
        const eventCount = obj.eventCount
        const keyEvents = obj.keyEvents
        const averageSessionDuration = obj.averageSessionDuration
    
        return {
          [dim] : dimValue,
            totalUsers: totalUsers,
            newUsers: newUsers,            
            sessions: sessions,
            bounceRate: bounceRate,
            activeUsers: activeUsers,
            eventCount: eventCount,
            keyEvents: keyEvents,
            averageSessionDuration: averageSessionDuration / 60
    
        };
      });

      if (dim === 'date') {
              agg_result.sort((a, b) => {
                  const dateA = moment.tz(a.date, 'Europe/Paris');
                  const dateB = moment.tz(b.date, 'Europe/Paris');
                  return dateA - dateB;
              });
          }
      
          const getUniqueValues = (arr, key) => {
              const uniqueSet = new Set(arr.map(item => item[key]));
              return [...uniqueSet];
          };
      
          const labels = getUniqueValues(agg_result, dim)
      
          return {agg_result, labels}

      
}