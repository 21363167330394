import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { postToSignedUrl } from '../UserConfigs/AwsSigner';
import signRequest from '../UserConfigs/AwsSigner';
import IntegrationsFormGSC from './IntegrationsForms.js/IntegrationFormGSC';
import IntegrationsFormGA4 from './IntegrationsForms.js/IntegrationsFormsGA4';
import LoaderPercent from '../Components/LoaderPercent';

import {
    View
} from 'react-native'


export default function IntegrationsModal(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const integrations = props.integrations
    const currentInt = props.currentInt
    const titleProvider = props.titleProvider
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const auth0_user_id = configArray.auth0_user_id
    const client_lower = client_upper.toLowerCase()
    const clientMapId = configArray.clientMapId
    const support_url = configArray.config_url
    const endpointStage = configArray.testingState
    const [configResponse, setConfigResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [currentConfig, setCurrentConfig] = useState(null)
    const [disableSave, setDisableSave] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [saveResponse, setSaveResponse] = useState(null)

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        
        setOpen(false);
    };

    const sendConfig = async (event_data, support_url, support_key, support_id, method) => {
            setSaveLoading(true); // Start loading
            setSaveResponse([]); // Clear previous response
    
            try {
                // First, get the signed URL
                const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
                // Send the POST request to the signed URL
                const result = await postToSignedUrl(signedUrl, event_data, method);
                setSaveResponse(result)
                setSaveLoading(false);
            } catch (error) {
                console.error("Error posting to Lambda:", error);
            } finally {
                setSaveLoading(false);
            }
        };

    const handleSave = (value, type) => {
        const configCopy = JSON.parse(JSON.stringify(configArray))
        const integrationsCopy = JSON.parse(JSON.stringify(integrations))
        integrationsCopy[type] = value
        configCopy['integrations'] = integrationsCopy
        console.log(configCopy)

        const event_data = {
            client: client_lower,
            client_id: clientMapId,
            auth0_user_id: auth0_user_id,
            "request_type": "custom_configs_update",
            "configData": configCopy,
        }

        sendConfig(event_data, support_url, support_key, support_id, 'POST')
        setOpen(false);
    }

    const ModalContent = () => {
        if (saveLoading) {
            return <LoaderPercent theme={theme}/>
        } else if (currentInt === 'gsc') {
            return <IntegrationsFormGSC
                theme={theme}
                integrations={integrations}
                currentInt={currentInt}
                setCurrentConfig={setCurrentConfig}
                configArray={configArray}
                handleClose={handleClose}
                handleSave={handleSave}
            />
        } else if (currentInt === 'ga4') {
            return <IntegrationsFormGA4
                theme={theme}
                integrations={integrations}
                currentInt={currentInt}
                setCurrentConfig={setCurrentConfig}
                configArray={configArray}
                handleClose={handleClose}
                handleSave={handleSave}
            />
        }
    }

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{ width: '100%' }}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize', backgroundColor: theme.backgroundColor, color: theme.color }}>Integration Config - {titleProvider(currentInt)} </DialogTitle>
                    <DialogContent sx={{
                        backgroundColor: theme.backgroundColor
                    }}>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>

                            <ModalContent
                                theme={theme}
                            />
                           

                        </Box>
                    </DialogContent>

                </View>

            </Dialog>
        </div>

    );
}