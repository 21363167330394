import * as React from 'react';
import { Dimensions } from 'react-native';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function IntegrationsActiveCard(props) {

    const theme = props.theme
    const text = props.text
    const image = props.image
    const editFunction = props.editFunction
    const testFunction = props.testFunction
    const isMatched = props.isMatched
    const item = props.item
    const active = isMatched ? 'Active' : 'Inactive'
    const buttonText = isMatched ? 'Edit' : 'Add'

    return (
        <Card sx={{ display: 'flex', borderRadius: 5, height: windowHeight * 0.25, padding: '1%' }} raised={true}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }} >
                    <Typography component="div" variant="h5" sx={{ fontFamily: 'Electrolize' }}>
                        {text}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ color: 'text.secondary', fontFamily: 'Electrolize' }}
                    >
                        {active}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pl: 1, pb: 1 }}>

                <DialogActions sx={{backgroundColor: theme.backgroundColor}} >
                        <Button sx={{color: theme.backgroundColor2, fontWeight: 600, fontFamily: 'Electrolize'}} variant="outlined"
                            onClick={() => editFunction(item)}
                            >{buttonText}</Button>
                    </DialogActions>
                    {isMatched && <DialogActions sx={{backgroundColor: theme.backgroundColor}} >
                        <Button sx={{color: theme.backgroundColor2, fontWeight: 600, fontFamily: 'Electrolize'}} variant="outlined"
                            onClick={() => testFunction(item)}
                            >Preview</Button>
                    </DialogActions>}

                </Box>
            </Box>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={image}
            />
        </Card>
    );
}
