import React, { useEffect, useState } from "react";
import { AppBar } from "@react-native-material/core";
import Tooltip from '@mui/material/Tooltip';
import { Text, Dimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WebFont from 'webfontloader';
import { View, Pressable } from 'react-native'
import SettingsBrightnessOutlinedIcon from '@mui/icons-material/SettingsBrightnessOutlined';
import CloseIcon from '@mui/icons-material/Close';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import ModalTheme from "../Modals/ModalTheme";
import ModalClient from "../Modals/ModalClient";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AppBarRN = (props) => {

  const handleDrawer = props.handleDrawer
  const homeTitle = props.homeTitle
  const theme = props.theme
  const configHandler = props.configHandler
  const clientHandler = props.clientHandler
  const client = props.client
  const role = props.role
  const viewerVisible = props.viewerVisible
  const attributionEnhanced = props.attributionEnhanced
  const setViewerVisible = props.setViewerVisible
  const setAttModalOpen = props.setAttModalOpen
  const lastUpdated = props.lastUpdated || "loading..."
  const [visibleModal, setVisible] = useState(false);
  const [visibleClientModal, setClientVisible] = useState(false);
  const isAdmin = role === "admin";
  const navigate = useNavigate();
  const isViewerVisible = viewerVisible
  const isAttVisible = attributionEnhanced
  const panelContent = props.panelContent
  const setPanelContent = props.setPanelContent
  const isIntegrations = panelContent != null && panelContent !== 'home';

  let clientLabel;
  if (!client) {
    clientLabel = ""
  } else if (client === 'PS') {
    clientLabel = 'Pepperstone'
  } else {
    clientLabel = client
  }

  let formattedDate;

  if (lastUpdated !== 'loading...') {
    const date = new Date(lastUpdated);
    date.setHours(date.getHours() + 2);
    formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false  // Set to true if you want AM/PM format
    });
  } else {
    formattedDate = lastUpdated
  }

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.04
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.035
  } else { responseText = windowHeight * 0.035 }

  const appBarHeight = windowHeight * 0.07

  let widthPercentage = `${appBarHeight}px`

  function modalHandler(props) {
    setVisible(props)
  }

  function modalClientHandler(props) {
    setClientVisible(props)

  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
  }, []);



  return (
    <View style={{}}>
      <AppBar
        sx={{
          fontFamily: 'Electrolize',
          backgroundColor: theme.backgroundColor,
          border: `1px solid ${theme.color}`,
          borderRadius: 5,
          minHeight: 100
        }}
        style={{ height: widthPercentage, justifyContent: 'center' }}
        title={
          <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Text
              style={{
                fontFamily: 'Electrolize',
                color: theme.color,
                fontSize: responseText * 0.8,
                textAlign: 'center',
                fontWeight: 600

              }}>
              {homeTitle} - {clientLabel}
            </Text>

          </View>}
        color={theme.backgroundColor}


        leading={props => (
          <IconButton
            size="large"
            edge="end"
            color="primary"
            aria-label="menu"
            sx={{ mr: 4, color: theme.backgroundColor2 }}
            onClick={() => handleDrawer()}
          >
            <MenuIcon sx={{ mr: 2, color: theme.backgroundColor2, fontSize: responseText }} />
          </IconButton>
        )}
        trailing={props => (

          <View style={{ flexDirection: 'row' }}>

            {isAttVisible &&
              <Tooltip
                title={"Change Models"}

                componentsProps={{
                  arrow: { sx: { color: theme.backgroundColor2, } },
                  tooltip: {
                    sx: {
                      bgcolor: theme.backgroundColor2,
                      color: "white",
                      fontFamily: 'Electrolize',
                    }
                  },
                }}
                arrow
              >
                <Pressable
                  onPress={() => setAttModalOpen(true)}

                >
                  <BarChartIcon

                    edge="start"
                    color="primary"
                    aria-label="menu1"
                    sx={{ mr: 2, color: theme.backgroundColor, fontSize: responseText, backgroundColor: 'red', borderRadius: 1 }}
                  >

                  </BarChartIcon>
                </Pressable>
              </Tooltip>}

            <Pressable
              onPress={() => navigate('/home')}
            >
              <HomeOutlinedIcon

                edge="start"
                color="primary"
                aria-label="menu1"
                sx={{ mr: 2, color: theme.backgroundColor2, fontSize: responseText }}
              >
              </HomeOutlinedIcon>
            </Pressable>

            <Tooltip
              title={"Colour Mode"}

              componentsProps={{
                arrow: { sx: { color: theme.backgroundColor2, } },
                tooltip: {
                  sx: {
                    bgcolor: theme.backgroundColor2,
                    color: "white",
                    fontFamily: 'Electrolize',
                  }
                },
              }}
              arrow
            >

              <Pressable
                onPress={() => modalHandler(true)}
              >
                <SettingsBrightnessOutlinedIcon

                  edge="start"
                  color="primary"
                  aria-label="menu1"
                  sx={{ mr: 2, color: theme.backgroundColor2, fontSize: responseText }}
                >
                </SettingsBrightnessOutlinedIcon>
              </Pressable>
            </Tooltip>
            <Tooltip
              title={"Select Client"}

              componentsProps={{
                arrow: { sx: { color: theme.backgroundColor2, } },
                tooltip: {
                  sx: {
                    bgcolor: theme.backgroundColor2,
                    color: "white",
                    fontFamily: 'Electrolize',
                  }
                },
              }}
              arrow
            >
              {isAdmin && <Pressable
                onPress={() => modalClientHandler(true)}
              >
                <AccountCircle

                  edge="start"
                  color="primary"
                  aria-label="menu1"
                  sx={{ mr: 2, color: theme.backgroundColor2, fontSize: responseText }}
                >

                </AccountCircle>
              </Pressable>}
            </Tooltip>

            {isViewerVisible &&
              <Tooltip
                title={"Close Dashboard"}

                componentsProps={{
                  arrow: { sx: { color: theme.backgroundColor2, } },
                  tooltip: {
                    sx: {
                      bgcolor: theme.backgroundColor2,
                      color: "white",
                      fontFamily: 'Electrolize',
                    }
                  },
                }}
                arrow
              >
                <Pressable
                  onPress={() => setViewerVisible(false)}

                >
                  <CloseIcon

                    edge="start"
                    color="primary"
                    aria-label="menu1"
                    sx={{ mr: 2, color: theme.backgroundColor, fontSize: responseText, backgroundColor: 'red', borderRadius: 1 }}
                  >

                  </CloseIcon>
                </Pressable>
              </Tooltip>}

              {isIntegrations &&
              <Tooltip
                title={"Close Integration"}

                componentsProps={{
                  arrow: { sx: { color: theme.backgroundColor2, } },
                  tooltip: {
                    sx: {
                      bgcolor: theme.backgroundColor2,
                      color: "white",
                      fontFamily: 'Electrolize',
                    }
                  },
                }}
                arrow
              >
                <Pressable
                  onPress={() => setPanelContent('home')}

                >
                  <CloseIcon

                    edge="start"
                    color="primary"
                    aria-label="menu1"
                    sx={{ mr: 2, color: theme.backgroundColor, fontSize: responseText, backgroundColor: 'red', borderRadius: 1 }}
                  >

                  </CloseIcon>
                </Pressable>
              </Tooltip>}




            <ModalTheme
              modalHandler={modalHandler}
              configHandler={configHandler}
              visibleModal={visibleModal}
              theme={theme}
            />
            <ModalClient
              modalClientHandler={modalClientHandler}
              visibleClientModal={visibleClientModal}
              clientHandler={clientHandler}
              theme={theme}
            />
          </View>

        )}
      />
    </View>
  )

};

export default AppBarRN;