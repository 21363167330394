import moment from "moment";

export default function ParseGSC (data, dim) {

    const agg_result = data.map((obj) => {
        const impressions = obj.impressions;
        const clicks = obj.clicks;
        const position = obj.postion
        const dimValue = obj['dimension']

        const ctr = parseFloat(((clicks / impressions) * 100).toFixed(2))
    
        return {
          [dim] : dimValue,
          impressions: impressions,
          clicks: clicks,
          position: parseFloat(position),
          ctr: ctr
    
        };
      });

    if (dim === 'date') {
        agg_result.sort((a, b) => {
            const dateA = moment.tz(a.date, 'Europe/Paris');
            const dateB = moment.tz(b.date, 'Europe/Paris');
            return dateA - dateB;
        });
    }

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const labels = getUniqueValues(agg_result, dim)

    return {agg_result, labels}

}