import { View, Text, StyleSheet, Dimensions } from 'react-native'
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const IntegrationsNoData = (props) => {
    const theme = props.theme

    const iconStyle = {
        fontSize : windowWidth * 0.2,
        color: 'red'
    }

    const textStyle = {
        fontSize : windowWidth * 0.02,
        color: 'red',
        fontFamily: 'Electrolize',
    }

    return (
        <View style={styles.container}>
            <SyncProblemIcon
                style={iconStyle}
            />
            <Text style={textStyle}>
            No data matching your query - please reset filters
            </Text>

        </View>
    )

}

export default IntegrationsNoData

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'grey',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
        alignItems: 'center',
        justifyContent: 'center'
    },

})