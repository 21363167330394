import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { ApiGatewayS3Function } from '../../UserConfigs/ApiGateWayFunction.js';
import ApiGatewayFunction from '../../UserConfigs/ApiGateWayFunction.js';
import { postToAPISignedUrl } from '../../UserConfigs/ApiGateway.js';
import postToApiGateway from '../../UserConfigs/ApiGateway.js';
import LoadingComponent from '../../Components/Loader.js';
import PivotTableContent from '../../Flexmonster/PivotTableContent.js';
import NoDataContainer from '../../Components/NoDataContainer.js';


const CustomPivotPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentReportConfig = props.currentReportConfig
    const setCurrentReportConfig = props.setCurrentReportConfig
    const flexConfigStorage = props.flexConfigStorage
    const lastFlexUsed = props.lastFlexUsed
    const newSettings = props.newSettings
    const setNewSettings = props.setNewSettings
    const reportList = props.reportList
    const reportMap = props.reportMap
    const fmKey = props.fmKey
    const [panelContent, setPanelContent] = useState('summary')
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const attModalOpen = props.attModalOpen
    const setOpen = props.setOpen
    const client = clientID.toLowerCase();
    const cubeTable = `${client}_adgroup_enhanced`
    const dynamicFilter = 'channel'
    const homeTitle = 'CustomPivotPanel'

    const custom_groupings = configArray.customGroupings
    const custom_dimensions_sql = custom_groupings.custom_dimensions_sql || []
    const custom_dimensions_aliases = custom_groupings.custom_dimensions_aliases || []
    const custom_metrics = custom_groupings.custom_metrics || []
    const custom_metrics_aliases = custom_groupings.custom_metrics_aliases || []


    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'campaign'

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)
    const startDateString = previousDate.toISOString().slice(0, 10);

    const cubeDims = [filter1name, filter2name, filter3name, 'adgroup']

    function formatString(input) {
        return input.toLowerCase().replace(/ /g, "_");
    }

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": startDateString,
          "end": yesterdayProp,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": cubeDims,
          "measures": [
            "impressions",
            "clicks",
            "spend",
            "regs",
            "ftds",
            "ftd_ngr",
            "retention_ngr",
            "deposits",
            "deposit_amount",
            "reactivations"
          ],
          "custom_dimensions": custom_dimensions_sql,
          "custom_dimensions_aliases": custom_dimensions_aliases,
          "custom_measures": custom_metrics,
          "custom_measures_aliases": custom_metrics_aliases,
          "filters": [],
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
        }
    }

      useEffect(() => {
        const queryStart = cubeQuery.query.start;
        const queryEnd = cubeQuery.query.end;
        const queryFilters = cubeQuery.query.filters;
        const endpointStage = configArray.testingState;
        const key = `stack-pattern/default_preagg%2F${client_id}%2F${client_lower}_${formatString(homeTitle)}_cube.json`
        const fetchData = async () => {  
          
    
          if (queryStart === startDateString && queryEnd === yesterdayProp) {
            if (queryFilters.length === 0) {
              try {
                console.log("preAgg")
                await ApiGatewayS3Function(cubeQuery, patternAPI, support_key, support_id, setIsLoading, setConfigResponse, endpointStage, key, 'preagg')
                
               
              } catch (error) {
                console.error("Error with fechPreAgg:", error);
                ApiGatewayFunction(cubeQuery, patternAPI, support_key, support_id, setIsLoading, setConfigResponse, endpointStage);
              }
            }
          } else {
            console.log("fallback")
            ApiGatewayFunction(cubeQuery, patternAPI, support_key, support_id, setIsLoading, setConfigResponse, endpointStage);

          }
        };
    
        fetchData();
      }, [configArray]);


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

    const cubeResponse = configResponse.data.body

    if (cubeResponse.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    const dataProp = {
        aggResult: cubeResponse,

    }

    const pivotConfig = {
        data: cubeResponse,
        dataset: panelContent,
        fields: Object.keys(cubeResponse[0]),
        dim1: filter1name,
        dim2: filter2name,
        dim3: filter3name
    }


    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <PivotTableContent
                    theme={theme}
                    dataProp={dataProp}
                    dynamicFilter={dynamicFilter}
                    attModalOpen={attModalOpen}
                    setOpen={setOpen}
                    currentMetrics={currentMetrics}
                    panelContent={panelContent}
                    setPanelContent={setPanelContent}
                    pivotConfig={pivotConfig}
                    currentReportConfig={currentReportConfig}
                    setCurrentReportConfig={setCurrentReportConfig}
                    flexConfigStorage={flexConfigStorage}
                    lastFlexUsed={lastFlexUsed}
                    newSettings={newSettings}
                    setNewSettings={setNewSettings}
                    reportList={reportList}
                    reportMap={reportMap}
                    clientID={clientID}
                    fmKey={fmKey}
                />

            </View>
        </View>

    )

}

export default CustomPivotPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})