import React, { useState } from 'react'
import {
    View,
    StyleSheet,
    Dimensions,
    Text
} from 'react-native'
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const windowWidth = Dimensions.get('window').width;

const HomeCommentary = (props) => {

    const theme = props.theme
    const commentaryData = props.commentaryData
    const currentMetrics = props.currentMetrics
    const [commentary, setCommentary] = useState('dod')

    const sortFunction = (data, dim) => {
        // Sort the data based on the given dimension
        data.sort((a, b) => {
            const valueA = a[dim];
            const valueB = b[dim];
            return valueA - valueB;
        });

        // Extract the first (most negative) and last (most positive) elements
        const mostNegative = data[0];
        const mostPositive = data[data.length - 1];

        // Compare the absolute values and return the one with the larger magnitude
        return Math.abs(mostNegative[dim]) > Math.abs(mostPositive[dim])
            ? mostNegative
            : mostPositive;
    };

    const dodSpendChange = sortFunction(commentaryData, 'spend_change_dod')
    const dodSpendChangeValue = dodSpendChange.spend_change_dod || '-'
    const dodSpendChangeBrand = dodSpendChange.brand || '-'
    const dodSpendChangeChannel = dodSpendChange.channel || '-'
    const dodSpendDiffValue = dodSpendChange.spend_diff_dod || '-'

    const dodFtdChange = sortFunction(commentaryData, 'ftd_change_dod')
    const dodFtdChangeValue = dodFtdChange.ftd_change_dod || '-'
    const dodFtdChangeBrand = dodFtdChange.brand || '-'
    const dodFtdChangeChannel = dodFtdChange.channel || '-'
    const dodFtdDiffValue = dodFtdChange.ftds_diff_dod || '-'

    const dodCpaChange = sortFunction(commentaryData, 'cpa_change_dod')
    const dodCpaChangeValue = dodCpaChange.cpa_change_dod || '-'
    const dodCpaChangeBrand = dodCpaChange.brand || '-'
    const dodCpaChangeChannel = dodCpaChange.channel || '-'
    const dodCpaDiffValue = dodCpaChange.cpa_diff_dod || '-'

    const momSpendChange = sortFunction(commentaryData, 'spend_change_mom')
    const momSpendChangeValue = momSpendChange.spend_change_mom || '-'
    const momSpendChangeBrand = momSpendChange.brand || '-'
    const momSpendChangeChannel = momSpendChange.channel || '-'
    const momSpendDiffValue = momSpendChange.spend_diff_mom || '-'

    const momFtdChange = sortFunction(commentaryData, 'ftd_change_mom')
    const momFtdChangeValue = momFtdChange.ftd_change_mom || '-'
    const momFtdChangeBrand = momFtdChange.brand || '-'
    const momFtdChangeChannel = momFtdChange.channel || '-'
    const momFtdDiffValue = momFtdChange.ftds_diff_mom || '-'

    const momCpaChange = sortFunction(commentaryData, 'cpa_change_mom')
    const momCpaChangeValue = momCpaChange.cpa_change_mom || '-'
    const momCpaChangeBrand = momCpaChange.brand || '-'
    const momCpaChangeChannel = momCpaChange.channel || '-'
    const momCpaDiffValue = momCpaChange.cpa_diff_mom || '-'



    const dodPanel = () => {
        return (
            <View style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <CommentaryPanel
                    changeValue={dodSpendChangeValue}
                    brand={dodSpendChangeBrand}
                    channel={dodSpendChangeChannel}
                    diffValue={dodSpendDiffValue}
                    metric={'Spend'}
                    theme={theme}
                />
                <CommentaryPanel
                    changeValue={dodFtdChangeValue}
                    brand={dodFtdChangeBrand}
                    channel={dodFtdChangeChannel}
                    diffValue={dodFtdDiffValue}
                    metric={currentMetrics.primaryMetricString}
                    theme={theme}
                />
                <CommentaryPanel
                    changeValue={dodCpaChangeValue}
                    brand={dodCpaChangeBrand}
                    channel={dodCpaChangeChannel}
                    diffValue={dodCpaDiffValue}
                    metric={'CPA'}
                    theme={theme}
                />

            </View>
        )
    }

    const momPanel = () => {
        return (
            <View style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>

                <CommentaryPanel
                    changeValue={momSpendChangeValue}
                    brand={momSpendChangeBrand}
                    channel={momSpendChangeChannel}
                    diffValue={momSpendDiffValue}
                    metric={'Spend'}
                    theme={theme}
                />
                <CommentaryPanel
                    changeValue={momFtdChangeValue}
                    brand={momFtdChangeBrand}
                    channel={momFtdChangeChannel}
                    diffValue={momFtdDiffValue}
                    metric={currentMetrics.primaryMetricString}
                    theme={theme}
                />
                <CommentaryPanel
                    changeValue={momCpaChangeValue}
                    brand={momCpaChangeBrand}
                    channel={momCpaChangeChannel}
                    diffValue={momCpaDiffValue}
                    metric={'CPA'}
                    theme={theme}
                />

            </View>
        )
    }

    const selectCommentary = () => {
        if (commentary === 'dod') {
            return (
                dodPanel()
            )
        } else {
            return momPanel()
        }
    }

    return (
        <View style={styles.container}>
            <View style={{ height: '10%', width: '100%', justifyContent: 'center', flexDirection: 'row' }}>
                <View style={{ width: '85%', height: '100%', alignItems: 'start', justifyContent: 'center' }}>
                    <Text style={{ fontSize: windowWidth * 0.015, fontFamily: 'Electrolize', fontWeight: 'bold', color: theme.color }}>
                        Changes in {commentary} metrics:
                    </Text>
                </View>
                <View style={{ width: '15%', height: '100%', alignItems: 'start', justifyContent: 'center' }}>
                    <CommentarySelect
                        theme={theme}
                        commentary={commentary}
                        setCommentary={setCommentary}
                    />

                </View>
            </View>


            {selectCommentary()}
        </View>
    )


}

const CommentaryPanel = (props) => {

    const changeValue = props.changeValue
    const brand = props.brand
    const channel = props.channel
    const diffValue = props.diffValue
    const theme = props.theme
    const metric = props.metric
    const isCPA = metric === 'CPA'

    const valueStyle = (value) => {
        if (isCPA && value < 0) {
            return 'green'
        } else if (isCPA && value > 0) {
            return 'red'
        } else if (!isCPA && value > 0) {
            return 'green'
        } else if (!isCPA && value < 0) {
            return 'red'
        } else if (value === 0 || value === '-') {
            return theme.color
        }
    }

    const arrowFunction = (value) => {
        if (isCPA && value < 0) {
            return (<ArrowDownwardIcon style={{
                color: valueStyle(value),
                fontSize: windowWidth * 0.04,
                fontWeight: 600
            }} />)
        } else if (isCPA && value > 0) {
            return (<ArrowUpwardIcon style={{
                color: valueStyle(value),
                fontSize: windowWidth * 0.04,
                fontWeight: 600
            }} />)
        } else if (!isCPA && value > 0) {
            return (<ArrowUpwardIcon style={{
                color: valueStyle(value),
                fontSize: windowWidth * 0.04,
                fontWeight: 600
            }} />)
        } else if (!isCPA && value < 0) {
            return (<ArrowDownwardIcon style={{
                color: valueStyle(value),
                fontSize: windowWidth * 0.04,
                fontWeight: 600
            }} />)
        } else if (value === 0 || value === '-') {
            return (<ArrowForwardIcon style={{
                color: valueStyle(value),
                fontSize: windowWidth * 0.04,
                fontWeight: 600
            }} />)
        }
    }
    

    const textStyle = {
        fontSize: windowWidth * 0.015,
        fontFamily: 'Electrolize',
        fontWeight: 400,
        color: theme.color
    }

    const percentStyle = {
        fontSize: windowWidth * 0.02,
        fontFamily: 'Electrolize',
        fontWeight: 'bold',
        color: valueStyle(changeValue)
    }



    return (
        <View style={{ width: '80%', height: '33.33333%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <View style={{ width: '75%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={textStyle}>
                    {brand} {channel} {metric} changed by {changeValue}
                </Text>

            </View>
            <View style={{ width: '12.5%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                {arrowFunction(changeValue)}
            </View>
            <View style={{ width: '12.5%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={percentStyle}>
                    {diffValue}%
                </Text>
            </View>

        </View>
    )


}

const CommentarySelect = (props) => {

    const commentary = props.commentary
    const setCommentary = props.setCommentary
    const theme = props.theme

    const handleChange = (event) => {
        setCommentary(event.target.value);
    };

    return (
        <View style={{width: '100%', height: '100%', alignItems: 'end', justifyContent: 'center', padding: '0%'}}>
        <Box sx={{ width: '50%'}}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={commentary}
              onChange={handleChange}
              sx={{backgroundColor: theme.color, color: theme.backgroundColor, height: 35, fontFamily: 'Electrolize',}}
            >
              <MenuItem value={'dod'} sx={{fontFamily: 'Electrolize'}}>dod</MenuItem>
              <MenuItem value={'mom'} sx={{fontFamily: 'Electrolize'}}>mom</MenuItem>
            </Select>
          </FormControl>
        </Box>
        </View>
      );

}

export default HomeCommentary

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'grey',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2%'
    }
})