import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import {
    View,
    Dimensions,
    Text
} from 'react-native'

const windowWidth = Dimensions.get('window').width;


export default function FiltersGA4(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const savehandler = props.filterhandler
    const [value1, setValue1] = useState('eventName')
    const [value2, setValue2] = useState('exact')
    const [value3, setValue3] = useState("page_view")
    const dimList = ['sessionDefaultChannelGroup', "sessionCampaignName", "sessionSourceMedium", "deviceCategory", "eventName", "region", "landingPage"]
    const operatorList = ['exact', 'contains', 'partial_regexp']
    
    const noValue = value3 === "" || !value3;


    const updateArray = {
        "filter": {
            "fieldName": value1,
            "stringFilter": {
                "value": value3,
                "matchType" : value2
            }
         }
    }

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        
        setOpen(false);
    };

    const handleSave = (value) => {
        savehandler(updateArray)
        setOpen(false);
    }

    const dims = dimList.map((item, index) => (
        <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
            {item}
        </MenuItem>
    ));

    const operators = operatorList.map((item, index) => (
        <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
            {item}
        </MenuItem>
    ));

    const filterHandler = (value, index) => {

        if (index === 0) {
            setValue1(value)
        } else if (index === 1) {
            setValue2(value)
        } else if (index === 2) {
            setValue3(value)
        }

    }

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{ width: '100%' }}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize' }}>Filters</DialogTitle>
                    <DialogContent sx={{
                    }}>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                                <FormHelperText>Select Dimension</FormHelperText>
                                <Select
                                    labelId="demo-dialog-select-label1"
                                    id="demo-dialog-select"
                                    value={value1}
                                    sx={{fontFamily: 'Electrolize'}}

                                    onChange={(event) => filterHandler(event.target.value, 0)}
                                    input={<OutlinedInput label="" />}
                                >
                                    {dims}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                                <FormHelperText>Select Match Type</FormHelperText>
                                <Select
                                    labelId="demo-dialog-select-label2"
                                    id="demo-dialog-select"
                                    value={value2}
                                    sx={{fontFamily: 'Electrolize'}}
                                    onChange={(event) => filterHandler(event.target.value, 1)}
                                    input={<OutlinedInput label="" />}
                                >
                                    {operators}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                                <FormHelperText>Filter Value</FormHelperText>
                                <OutlinedInput
                                    id="outlined-search"
                                    onChange={e => setValue3(e.target.value)}
                                    type="search"
                                    value={value3}
                                    sx={{
                                        width: '100%',
                                        left: 0,
                                        fontFamily: 'Electrolize',
                                        color: theme.color,
                                        backgroundColor: theme.backgroundColor,
                                        textAlign: 'center'

                                    }}
                                />

                            </FormControl>

                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave} disabled={noValue}>Update</Button>
                    </DialogActions>
                </View>

            </Dialog>
        </div>

    );
}