import React from 'react';
import {
    Modal,
    View,
    StyleSheet,
    Dimensions,

    Pressable
} from 'react-native';
import moment from 'moment-timezone';
import { LicenseInfo } from '@mui/x-license-pro';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { purple } from '@mui/material/colors';
import { Divider, Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;


const ModalDate = (props) => {

    LicenseInfo.setLicenseKey('3dd7f0e5176fd2661730ecde2785f53fTz0xMDU4OTgsRT0xNzY4NDM1MTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');  
    const visible = props.visible
    const setVisible = props.setVisible
    const config = props.config
    const theme = props.theme
    const updateSlide = props.updateSlide
    const currentSlide = config.slide
    const updateDate = props.updateDate
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const currentDate = new Date();
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 30);
    previousDate.setDate(1)
    const startDateString = previousDate.toISOString().slice(0, 10);

    function handleAccept(value) {
        const updateSlide = currentSlide -1

        let defaultStart;
        let defaultEnd;

        if (!value[0] && !value[0]) {
          defaultStart = startDateString
          defaultEnd = yesterdayProp

        } else if (value[1] ===  "Invalid date" || !value[1]) {
          defaultEnd = yesterdayProp
        } else {
          defaultStart = moment(value[0]).format('YYYY-MM-DD');
          defaultEnd = moment(value[1]).format('YYYY-MM-DD'); 
        }

        setVisible(false)  
        updateDate(updateSlide, defaultStart, defaultEnd)  
    }

    const shortcutsItems = [
        {
          label: 'Yesterday',
          getValue: () => {
            const yesterdayStart = moment().subtract(1, 'day').startOf('day');
            const yesterdayEnd = moment().subtract(1, 'day').endOf('day');
            return [yesterdayStart, yesterdayEnd];
          },
        },
        {
          label: 'This Week',
          getValue: () => {
            const startOfWeek = moment().startOf('week');
            const endOfYesterday = moment().subtract(1, 'day').endOf('day');
            return [startOfWeek, endOfYesterday];
          },
        },
        {
          label: 'Last Week',
          getValue: () => {
            const startOfLastWeek = moment().subtract(1, 'week').startOf('week');
            const endOfLastWeek = moment().subtract(1, 'week').endOf('week');
            return [startOfLastWeek, endOfLastWeek];
          },
        },
        {
          label: 'Last 7 Days',
          getValue: () => {
            const sevenDaysAgo = moment().subtract(7, 'days').startOf('day');
            const endOfYesterday = moment().subtract(1, 'day').endOf('day');
            return [sevenDaysAgo, endOfYesterday];
          },
        },
        {
          label: 'Current Month',
          getValue: () => {
            const startOfMonth = moment().startOf('month');
            const endOfMonth = moment().endOf('month');      
            return [startOfMonth, endOfMonth];
          },
        },
        {
          label: 'Last Month',
          getValue: () => {
            const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
            const endOfLastMonth = moment().subtract(1, 'month').endOf('month');
            return [startOfLastMonth, endOfLastMonth];
          },
        }
      ];

    const themeCustom = createTheme({
        palette: {
          primary: {
            main: theme.backgroundColor2,
          },
          secondary: purple,
          main: purple,
        },
        
      });

      function CustomRangeShortcuts(props) {
        const { items, onChange, isValid } = props;
      
        if (items == null || items.length === 0) {
          return null;
        }
      
        const resolvedItems = items.map((item) => {
          const newValue = item.getValue({ isValid });
      
          return {
            label: item.label,
            onClick: () => {
              onChange(newValue);
            },
            disabled: !isValid(newValue),
          };
        });
      
        return (
          <Box
            sx={{
              gridRow: 1,
              gridColumn: 2,
            }}
          >
            <List
              dense
              sx={(theme) => ({
                display: 'flex',
                px: theme.spacing(4),
                '& .MuiListItem-root': {
                  pt: 0,
                  pl: 0,
                  pr: theme.spacing(1),
                },
              })}
            >
              {resolvedItems.map((item) => {
                return (
                  <ListItem key={item.label}>
                    <Chip {...item} sx={{backgroundColor: theme.backgroundColor2, color: 'white' }} />
                  </ListItem>
                );
              })}
            </List>
            <Divider />
          </Box>
        );
      }

    let dynamicWindowWidth;
    let dynamicWindowHeight;

    if (windowWidth < 1500) {
      dynamicWindowWidth = "60%"
      dynamicWindowHeight = '90%'
    } else if (windowWidth < 2000) {
      dynamicWindowWidth = "50%"
      dynamicWindowHeight = '75%'
    } else {
      dynamicWindowWidth = "50%"
      dynamicWindowHeight = '50%'
    }

    console.log(dynamicWindowHeight)

    const modalWindow = {
      height: dynamicWindowHeight,
      width: dynamicWindowWidth,
      borderRadius: 15,
      padding: '1%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
  }


    return (
        <Modal
            visible={visible}
            style={styles.modalParent}
            transparent={true}
            config={config}
        >
            <Pressable
                style={styles.modalContainer}
                onPress={() => setVisible(false)}

            >
                <View style={[modalWindow, {
                    backgroundColor: theme.backgroundColor,
                    borderColor: theme.color,
                    borderWidth: 2,
                    filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                }]}>
                    <Pressable style={{
                        width: '90%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                        >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <ThemeProvider theme={themeCustom} components={['SingleInputDateRangeField']}>
                                <StaticDateRangePicker 
                                    calendars={2}
                                    onAccept={handleAccept}
                                    slots={{
                                        shortcuts: CustomRangeShortcuts,
                                        }}
                                        slotProps={{
                                        shortcuts: {
                                            items: shortcutsItems,
                                        },
                                        toolbar: {
                                            hidden: true,
                                        },
                                        actionBar: {
                                          actions: ["accept"]
                                        },
                                        }}
                                    sx={{alignItems: 'center', 
                                        justifyContent: 'center', 
                                        padding: '1%'
                                        }}
                                    
                                />
                            </ThemeProvider>
                        </LocalizationProvider>
                    </Pressable>
                </View>


            </Pressable>


        </Modal>
    )

}


const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        alignItems: 'center',
        justiftContent: 'center',


    },
    modalWindow: {
        height: '90%',
        width: '60%',
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    mapContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderColor: 'grey',
        borderWidth: 0,
        padding: 10,


    },
    pressableItem: {

        padding: '1%',

    },
    textItem: {
        textAlign: 'center',
        borderColor: 'black',
        borderWidth: 0,
        fontSize: 40,
        // Ensuring text fits within the Pressable item
        flexShrink: 1,
    },
    container: {
        padding: 10,
        width: '50%',
        borderColor: 'black',
        borderWidth: 1,
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginVertical: 10,
        paddingHorizontal: 5,
    },
    errorText: {
        color: 'red',
    },
    submitButton: {
        backgroundColor: '#007bff',
        color: 'white',
        padding: 10,
        alignItems: 'center',
        borderRadius: 5,
    }
});


export default ModalDate