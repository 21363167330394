import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { postToAPISignedUrl } from '../../UserConfigs/ApiGateway';
import postToApiGateway from '../../UserConfigs/ApiGateway';
import LoaderPercent from '../../Components/LoaderPercent';
import StudioCreateStep1 from './StudioCreateStep1';
import StudioCreateStep2 from './StudioCreateStep2';

const StudioCreate = (props) => {

    const setVisible = props.setVisible
    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const customReports = props.customReports
    const setCurrentStepBase = props.setCurrentStep
    const setUpdate = props.setUpdate
    const schemaData = props.schemaData
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const patternAPI = configArray.patternAPI
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const [isLoading, setIsLoading] = useState(false)
    const [configResponse, setConfigResponse] = useState(null)
    const [currentStep, setCurrentStep] = useState(1)
    const [currentTable, setCurrentTable] = useState(null)

    const requestData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setConfigResponse(null);
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
    
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
    
            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
            setUpdate({"id": Math.floor(10000000 + Math.random() * 90000000)})
        }
    };

    const savehandler = (event, reportName) => {
        let reportCopy;
        if (customReports.length === 0) {
            reportCopy = {[reportName]: event}
        } else {
            reportCopy = JSON.parse(JSON.stringify(customReports))
            reportCopy[reportName] = event
        }
        
        const saveEventData = {
            "request_type": "save_report",
            "client": client_lower,
            "client_id": configArray.clientMapId,
            "auth0_user_id": configArray.auth0_user_id,
            "report_data": reportCopy,
            "report_name": reportName
        }


        requestData(saveEventData, patternAPI, support_key, support_id)
        setCurrentStepBase(0)
        
    }

    if (isLoading) {
        return (
            <LoaderPercent theme={theme} />
        );
        }
        

    const renderComponent = () => {

        if (currentStep === 1) {
            return <StudioCreateStep1
                theme={theme}
                configArray={configArray}
                setVisible={setVisible}
                setCurrentStep={setCurrentStep}
                schemaData={schemaData}
                setCurrentTable={setCurrentTable}
                currentTable={currentTable}
                currentMetrics={currentMetrics}
                setCurrentStepBase={setCurrentStepBase}
            />
            
        } else if (currentStep === 2) {
            return <StudioCreateStep2
                theme={theme}
                configArray={configArray}
                setVisible={setVisible}
                setCurrentStep={setCurrentStep}
                schemaData={schemaData}
                setCurrentTable={setCurrentTable}
                currentTable={currentTable}
                currentMetrics={currentMetrics}
                setCurrentStepBase={setCurrentStepBase}
                savehandler={savehandler}

        />
        }
    }

    const containerStyle = {
        padding: 20,
        borderRadius: 20,

    };

    return (


            <View
                style={{
                    flexDirection: 'column',
                    borderColor: 'red',
                    borderWidth: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    justifyContent: 'end',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    


                }}>
                <View style={{
                    margin: 0,
                    height: '100%',
                    width: '100%',
                    borderColor: theme.color,
                    backgroundColor: theme.backgroundColor,
                    borderWidth: 1,
                    //borderRadius: 15,
                    padding: '1%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <View style={{width: '100%', height:'100%', flexDirection: 'row'}}>

                            {renderComponent()}

                    </View>

                </View>

            </View>

    )

}

export default StudioCreate;

const styles = StyleSheet.create({
    containerStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        //height: 50,
    },
});